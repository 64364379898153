import React, { useEffect, useState } from "react";
import Api from "../../config/Api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Status from "./Components/status.json";
import customFieldsName from "./Components/customFieldsName.json";
import Table from "../Components/Widget/Table";
import Loader from "../Components/Widget/Loader";
import Select from "react-select";

import StatusBadge from "../SDRFManagement/Application/Components/StatusBadge";
import { FaRegFileExcel, FaRegFilePdf } from "react-icons/fa";
import DatePicker from "react-multi-date-picker";
import { useForm, Controller } from "react-hook-form";
import dayjs from "dayjs";
import TooltipWidget from "../Components/Widget/TooltipWidget";

import { createSearchParams } from "react-router-dom";
import { getCustomColumns } from "./Components/CustomReportColumn";

const CustomReport = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useForm();
  // const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  // const [isFetching, setIsFetching] = useState(false);
  const [application, setApplication] = useState([]);
  const [isApplicationLoaded, setIsApplicationLoaded] = useState(true);
  const [filter, setFilter] = useState("");
  const admin = useSelector((state) => JSON.parse(state.authentication.admin));

  // eslint-disable-next-line
  const [isFetching, setIsFetching] = useState(false);
  const [tableDataShowColumn, setTableDataShowColumn] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [disasterList, setDisasterList] = useState([]);
  const [damageLevel1List, setDamageLevel1List] = useState([]);
  const [damageLevel2List, setDamageLevel2List] = useState([]);
  const [damageLevel3List, setDamageLevel3List] = useState([]);
  const [isThirdDropdownShow, setIsThirdDropdownShow] = useState(false);

  const getDistrict = async (e) => {
    const res = await Api("get-district");
    setDistrictList(res?.data?.district);
    //console.log(res);
  };

  const style = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "1px solid #224b8b" : "1px solid #ddd",
      boxShadow: "none",
      backgroundColor: state.isFocused ? "#fff" : "#fff",
      "&:hover": {
        borderColor: "#224b8b",
      },
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "#224b8b" : "",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#224b8b",
      },
    }),
  };

  const level1Option = (data) => {
    return data
      ?.filter((d) => d.status === "Active")
      .map((item) => {
        return {
          label: item.title,
          value: item.id,
          ...item,
        };
      });
  };

  const level2Option = (data) => {
    return data
      ?.filter((d) => d.status === "Active")
      .map((item) => {
        return {
          label: item.damage_name,
          value: item.id,
          ...item,
        };
      });
  };

  const level3Option = (data) => {
    // console.log(data);
    return data
      ?.filter((d) => d.status === "Active")
      .map((item) => {
        return {
          label: !!item?.second_damage?.extent_unit
            ? `${item.name} (${item?.second_damage?.extent_unit})`
            : item.name,
          value: item.id,
          ...item,
        };
      });
  };

  const getlevel1 = async () => {
    try {
      setIsLoading(true);
      const res = await Api("first_damage_classification_get");
      // console.log(res);
      if (res.data.status === "1") {
        setDamageLevel1List(
          level1Option(res.data.DamageClassificationData.reverse())
        );
        setIsLoading(false);
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  //damage level 2
  const getlevel2 = async (id) => {
    try {
      setIsFetching(true);
      setIsLoading(true);
      const res = await Api(`second_damage_classification_get/${id}`);
      // console.log(res);
      if (res.data.status === 1) {
        setDamageLevel2List(level2Option(res.data.DamageClassificationData));
        setIsLoading(false);
        setIsFetching(false);
        return true;
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsFetching(false);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //damage level 2

  //damage level 3
  const getlevel3 = async (id) => {
    setIsThirdDropdownShow(false);
    setValue("third_damage_classification_id", "");
    try {
      setIsFetching(true);
      setIsLoading(true);
      const res = await Api(`third_damage_classification_get/${id}`);

      if (res.data.status === 1) {
        setDamageLevel3List(level3Option(res.data.data));
        if (res.data.data.length > 1) {
          setIsThirdDropdownShow(true);
        } else {
          // console.log(res.data.data[0]);

          let tempData = {
            label: res.data.data[0].name,
            value: res.data.data[0].id,
            ...res.data.data[0],
          };

          setValue("third_damage_classification_id", tempData);
        }
        setIsLoading(false);
        setIsFetching(false);
        return true;
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsFetching(false);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //damage level 3

  //getDisaster
  const getDisaster = async () => {
    try {
      setIsLoading(true);
      const res = await Api("nature_of_disaster_get");
      // console.log(res);
      if (res.data.status === 1) {
        setDisasterList(level1Option(res.data.NatureOfDisaster));
        setIsLoading(false);
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //getDisaster

  const [filtered, setFiltered] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);

  //Application Get
  const getApplication = async (props) => {
    try {
      setIsLoading(true);
      setIsApplicationLoaded(false);
      const body = !!props
        ? Object.fromEntries(
            Object.entries(props).filter(([key, value]) => value)
          )
        : {};

      // console.log("body", body);
      const res = await Api("sdrf_custom_report", "POST", body, true);
      // console.log(res);
      if (res.data.status === 1) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setApplication(res.data.data);
        setFiltered(res.data.data);
        setIsApplicationLoaded(true);
        setIsLoading(false);
        return true;
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
        setIsApplicationLoaded(true);
        return false;
      }
    } catch (error) {
      // console.log(error);
      // toast.error("Something went wrong!", {
      //   position: toast.POSITION.BOTTOM_RIGHT,
      // });
    }
  };
  //Application Get

  //filter
  const searchInput = (e) => {
    setFilter(e.target.value);
  };



  useEffect(() => {
    if (!!filter)
    console.log(filter);
      setFiltered(
        application.filter((application) =>
          JSON.stringify(Object.values(application)).includes(filter)
        )
      );
  }, [filter]);



  // console.log(filtered);

  // const filtered = !filter
  //   ? application
  //   : application.filter(
  //       (application) =>
  //         application?.application_id
  //           ?.toLowerCase()
  //           .includes(filter.toLowerCase()) ||
  //         application?.applicant_name
  //           ?.toLowerCase()
  //           .includes(filter.toLowerCase()) ||
  //         application?.mobile?.toLowerCase().includes(filter.toLowerCase())
  //     );

  useEffect(() => {
    // getApplication();
    getlevel1();
    getDisaster();
    getDistrict();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadExcel = async () => {
    try {
      let baseUrl = process.env.REACT_APP_API_URL;
      let endpoint = "export_sdrf_custom_report";
      let newObj = {};



      Object.keys(getValues()).forEach((prop) => {
        if (Boolean(getValues()[prop])) {
          if (prop === "fields" && Boolean(getValues()[prop].length)) {
            newObj[`${prop}[]`] = getValues()[prop]?.map((a) => a.value);
          } else if (prop !== "fields" && getValues()[prop]) {
            if (
              prop === "first_damage_classification_id" ||
              prop === "second_damage_classification_id" ||
              prop === "third_damage_classification_id"
            ) {
              newObj[prop] = getValues()[prop].value;
            } else {
              newObj[prop] = getValues()[prop];
            }
          }
        }
      });

      let searchparams = createSearchParams(newObj).toString();
      let url = `${baseUrl}${endpoint}${
        !!searchparams ? `?${searchparams}` : ""
      }`;

      // console.log(url);
      window.open(url, "_blank");
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  const downloadPdf = () => {
    try {
      let baseUrl = process.env.REACT_APP_API_URL;
      let endpoint = `pdf-sdrfreport-custom/${admin.id}`;

      let newObj = {};
      Object.keys(getValues()).forEach((prop) => {
        if (Boolean(getValues()[prop])) {
          if (prop === "fields" && Boolean(getValues()[prop].length)) {
            newObj[`${prop}[]`] = getValues()[prop]?.map((a) => a.value);
          } else if (prop !== "fields" && getValues()[prop]) {
            if (
              prop === "first_damage_classification_id" ||
              prop === "second_damage_classification_id" ||
              prop === "third_damage_classification_id"
            ) {
              newObj[prop] = getValues()[prop].value;
            } else {
              newObj[prop] = getValues()[prop];
            }
          }
        }
      });

      let searchparams = createSearchParams(newObj).toString();
      let url = `${baseUrl}${endpoint}${
        !!searchparams ? `?${searchparams}` : ""
      }`;

      // console.log(url);
      window.open(url, "_blank");
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const [columns, setColumns] = useState([]);

  // let columns = [
  //   {
  //     name: "#",
  //     selector: (props, i) => filtered.indexOf(props) + 1,
  //     sortable: true,
  //     width: "50px",
  //   },
  //   {
  //     name: "Application ID",
  //     selector: (row) => row.application_id,
  //     sortable: true,
  //     maxWidth: "150px",
  //   },
  //   {
  //     name: "Applicant Name",
  //     selector: (row) => row.applicant_name,
  //     sortable: true,
  //     maxWidth: "150px",
  //   },
  //   {
  //     name: "Nature Of Disaster",
  //     selector: (row) => row?.nature?.map((item) => item?.title).join(", "),
  //     sortable: true,
  //     maxWidth: "150px",
  //   },
  //   {
  //     name: "Item Damaged",
  //     selector: (row) =>
  //       row?.application_third_meta
  //         ?.map((item) => item?.first_damage_classification?.title)
  //         .join(", "),
  //     sortable: true,
  //     maxWidth: "150px",
  //   },
  //   {
  //     name: "Category",
  //     selector: (row) =>
  //       row?.application_third_meta
  //         ?.map((item) => item?.second_damage_classification?.damage_name)
  //         .join(", "),
  //     sortable: true,
  //     maxWidth: "150px",
  //   },
  //   {
  //     name: "Date Of Occurrence",
  //     selector: (row) => row.date_of_occurrence,
  //     sortable: true,
  //   },
  //   {
  //     name: "District",
  //     selector: (row) => row.district?.district_name,
  //     sortable: true,
  //     maxWidth: "100px",
  //   },
  //   {
  //     name: "Status",
  //     selector: (row) => <StatusBadge status={row.status} />,
  //     sortable: true,
  //     maxWidth: "150px",
  //   },
  // ];

  const setColumnData = (d) => {
    let columnlist = getCustomColumns(d);
    const keysToKeep = d?.map(a => a.server_value)
    const updatedData = filtered.map((obj) => {
      const newObj = {};
      keysToKeep.forEach((key) => {
        newObj[key] = obj[key];
      });
      return newObj;
    });
    // console.log(updatedData);
    setApplication(updatedData);

    setColumns([
      // {
      //   name: "#",
      //   selector: (props, i) => filtered.indexOf(props) + 1,
      //   sortable: true,
      //   width: "50px",
      // },
      ...columnlist,
    ]);

    return true;
  };

  useEffect(() => {
    if(!!selectedFields.length){
      setColumnData(selectedFields);
    }
  }, [selectedFields.length])

  const onSubmit = async (d) => {
    if (!d?.fields?.length) {
      toast.error("Please select atleast one column", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    const res = await getApplication({
      gender: d?.gender,
      status: d?.status,
      deceased: d?.deceased,
      nature_disaster: d?.nature_disaster,
      first_damage_classification_id: d?.first_damage_classification_id?.id,
      second_damage_classification_id: d?.second_damage_classification_id?.id,
      third_damage_classification_id: d?.third_damage_classification_id?.id,
      sanction_letter_number: d?.sanction_letter_number,
      district_id: d?.district_id,
      location_disaster: d?.location_disaster,
      pincode: d?.pincode,
      village: d?.village,
      town: d?.town,
      admin_circle: d?.admin_circle,
      police_station: d?.police_station,
      start_date: d?.start_date,
      end_date: d?.end_date,
    });
    // setTableDataShowColumn(d?.fields);
    // setColumnData(d?.fields);
    if (res) {
  // console.log(filtered);
      // setColumnData(d?.fields);
      setSelectedFields(d?.fields);
    }
  };

  // useEffect(() => {
  //   // if(){
  //   setColumnData(tableDataShowColumn);
  //   // }
  // }, [tableDataShowColumn.length]);



  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="mb-3">
          <div className="flex-grow-1 mb-2">
            <h5 className="text-dark">Generate Custom Report</h5>
          </div>
          <div className="flex-grow-1">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-2">
                <div className="col-xl-6">
                  <Controller
                    control={control}
                    name="fields"
                    onFocus={true}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        isDisabled={isLoading}
                        styles={style}
                        placeholder={"Select columns to print"}
                        options={customFieldsName}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        closeMenuOnSelect={false}
                        isMulti
                      />
                    )}
                  />
                  {errors.fields && (
                    <div className="error_line">Column is required</div>
                  )}
                </div>
                <div className="col-xl-2 col-sm-6">
                  <div className="">
                    <select
                      className="form-select"
                      {...register("gender")}
                      disabled={isLoading}
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                </div>
                <div className="col-xl-2 col-sm-6">
                  <div className="">
                    <select
                      className="form-select"
                      disabled={isLoading}
                      {...register("status")}
                    >
                      <option value="">All Status</option>
                      {Status?.map((item) => {
                        return (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-xl-2 col-sm-6">
                  <div className="">
                    <select
                      className="form-select"
                      {...register("deceased")}
                      disabled={isLoading}
                    >
                      <option value="">Select Deceased</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div>

                <div className="col-xl-3 col-sm-6">
                  <div className="">
                    <select
                      className="form-select"
                      disabled={isLoading}
                      {...register("nature_disaster")}
                    >
                      <option value="">Select Nature Of Disaster</option>
                      {disasterList
                        ?.filter((NatureOfDisaster) => {
                          return NatureOfDisaster.status === "Active";
                        })
                        .map((NatureOfDisaster) => {
                          return (
                            <option
                              value={NatureOfDisaster?.id}
                              key={NatureOfDisaster?.id}
                            >
                              {NatureOfDisaster?.title}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <Controller
                    control={control}
                    name="first_damage_classification_id"
                    onFocus={true}
                    render={({ field }) => (
                      <Select
                        {...field}
                        autoFocus={field.ref}
                        isDisabled={isLoading}
                        styles={style}
                        placeholder={"Select Item Damaged"}
                        options={damageLevel1List}
                        onChange={(e) => {
                          field.onChange(e);
                          getlevel2(e.value);
                          setValue("second_damage_classification_id", "");
                          setValue("third_damage_classification_id", "");
                          setIsThirdDropdownShow(false);
                          setDamageLevel3List([]);
                        }}
                      />
                    )}
                  />
                </div>
                <div className="col-xl-3 col-sm-6">
                  <Controller
                    control={control}
                    name="second_damage_classification_id"
                    render={({ field }) => (
                      <Select
                        {...field}
                        isDisabled={isLoading}
                        styles={style}
                        placeholder={"Select Category"}
                        options={damageLevel2List}
                        onChange={(e) => {
                          field.onChange(e);
                          getlevel3(e.value);
                        }}
                      />
                    )}
                  />
                </div>
                <div
                  className={`col-xl-3 col-sm-6  ${
                    isThirdDropdownShow ? "d-block" : "d-none"
                  }`}
                >
                  <Controller
                    control={control}
                    name="third_damage_classification_id"
                    render={({ field }) => (
                      <Select
                        {...field}
                        isDisabled={isLoading}
                        styles={style}
                        placeholder={"Select Sub Category"}
                        options={damageLevel3List}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="">
                    <input
                      type="text"
                      placeholder="Sanction letter Number"
                      className="form-control"
                      disabled={isLoading}
                      {...register("sanction_letter_number")}
                    />
                  </div>
                </div>

                <div className="col-xl-3 col-sm-6">
                  <div className="">
                    <select
                      className="form-select"
                      disabled={isLoading}
                      {...register("district_id")}
                    >
                      <option value="">Select District</option>
                      {districtList
                        ?.filter((districts) => {
                          return districts.status === "Active";
                        })
                        .map((districts) => {
                          return (
                            <option value={districts?.id} key={districts?.id}>
                              {districts?.district_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="">
                    <input
                      type="text"
                      placeholder="Location of disaster"
                      className="form-control"
                      disabled={isLoading}
                      {...register("location_disaster")}
                    />
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="">
                    <input
                      type="text"
                      placeholder="Pincode"
                      className="form-control"
                      disabled={isLoading}
                      {...register("pincode")}
                    />
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="">
                    <input
                      type="text"
                      placeholder="Village/Colony/Ward"
                      className="form-control"
                      disabled={isLoading}
                      {...register("village")}
                    />
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="">
                    <input
                      type="text"
                      placeholder="City/Town"
                      className="form-control"
                      disabled={isLoading}
                      {...register("town")}
                    />
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="">
                    <input
                      type="text"
                      placeholder="Admin Circle/Sub-division"
                      className="form-control"
                      disabled={isLoading}
                      {...register("admin_circle")}
                    />
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="">
                    <input
                      type="text"
                      placeholder="Police Station"
                      className="form-control"
                      disabled={isLoading}
                      {...register("police_station")}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="">
                    <Controller
                      control={control}
                      name="start_date"
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          inputClass="form-control"
                          placeholder="From Date"
                          maxDate={new Date()}
                          format="YYYY-MM-DD"
                          editable={true}
                          value={field.value || ""}
                          onChange={(date) => {
                            field.onChange(date?.format?.("YYYY-MM-DD"));
                            // startDateChange(date?.format?.("YYYY-MM-DD"));
                          }}
                          disabled={isLoading}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="">
                    <Controller
                      control={control}
                      name="end_date"
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          inputClass="form-control"
                          placeholder="To Date"
                          maxDate={new Date()}
                          minDate={dayjs(watch("start_date"), "YYYY-MM-DD").$d}
                          format="YYYY-MM-DD"
                          editable={true}
                          onChange={(date) => {
                            field.onChange(date?.format?.("YYYY-MM-DD"));
                            // endDateChange(date?.format?.("YYYY-MM-DD"));
                          }}
                          disabled={isLoading}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-sm-6">
                  <div className="">
                    <input
                      type="text"
                      placeholder="Search Application"
                      className="form-control"
                      // value={filter}
                      // onChange={searchInput}
                      disabled={isLoading}
                      {...register("search", {
                        onChange: (e) => searchInput(e),
                      })}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="d-flex gap-2">
                    {/* <button
                      className="btn btn-primary flex-grow-1"
                      type="submit"
                      disabled={watch("fields")?.length > 10}
                    >
                      Show
                    </button> */}
                    <button
                      className="btn btn-primary flex-grow-1"
                      type="submit"
                    >
                      Show
                    </button>
                    <TooltipWidget title="Download Excel">
                      <button
                        onClick={downloadExcel}
                        className="btn btn-success flex-grow-1"
                        type="button"
                      >
                        <FaRegFileExcel />
                      </button>
                    </TooltipWidget>

                    <TooltipWidget title="Download PDF">
                      <button
                        onClick={downloadPdf}
                        className="btn btn-danger  flex-grow-1"
                        type="button"
                        disabled={watch("fields")?.length > 10}
                      >
                        <FaRegFilePdf />
                      </button>
                    </TooltipWidget>
                  </div>
                </div>
                <div className="col-auto">
                  {watch("fields")?.length > 10 && (
                    <div className="alert py-2 mb-0 alert-danger fw-bold small">
                      <p>
                        {/* The display and PDF download of data are limited to a
                        maximum of 10 columns at once. */}
                        The PDF download of data are limited to a
                        maximum of 10 columns at once.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
        <div>
          {isApplicationLoaded ? (
            <Table columns={columns} data={filtered} />
          ) : (
            <Loader />
          )}
          {/* {isApplicationLoaded ? (
            filtered?.length > 0 ? (
              <div>
                <div className="table-responsive">
                  <table className="table table-bordered m-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Application ID</th>
                        <th>Applicant Name</th>
                        <th>Father's Name</th>
                        <th>Id Proof Type</th>
                        <th>Id Proof No.</th>
                        <th>District</th>
                        <th>Nature Of Disaster</th>
                        <th>Item Damaged</th>
                        <th>Category</th>
                        <th>GENDER</th>
                        <th>Account Number</th>
                        <th>Ifsc</th>
                        <th>Date Of Occurrence</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filtered.map((data, i) => {
                        return (
                          <tr key={data.id}>
                            <td>
                              <IndexCount index={i + 1} />
                            </td>

                            <td>{data.application_id}</td>
                            <td>
                              {data.applicant_name}
                              <br />
                              {data.mobile}
                            </td>
                            <td>{data.father_name}</td>
                            <td>{data.id_proof_document_type}</td>
                            <td>{data.id_proof_no}</td>
                            <td>{data.district.district_name}</td>
                            <td>
                              {data?.nature
                                ?.map((item) => item?.title)
                                .join(", ")}
                            </td>
                            <td>
                              {data?.application_third_meta
                                ?.map(
                                  (item) =>
                                    item?.first_damage_classification?.title
                                )
                                .join(", ")}
                            </td>
                            <td>
                              {data?.application_third_meta
                                ?.map(
                                  (item) =>
                                    item?.second_damage_classification
                                      ?.damage_name
                                )
                                .join(", ")}
                            </td>
                            <td>{data.gender}</td>
                            <td>{data.account_number}</td>
                            <td>{data.ifsc}</td>
                            <td>{data.date_of_occurrence}</td>
                            <td>
                              <StatusBadge status={data.status} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {application?.last_page > 1 && <PaginationRow />}
              </div>
            ) : (
              <div className="alert alert-danger m-0">
                <p>No Applications found</p>
              </div>
            )
          ) : (
            <div className="loaderBox">
              <img src={GifImg} alt="Loader" />
              <p>Loading...</p>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};

export default CustomReport;
