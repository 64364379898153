import React, { useEffect, useState } from "react";
import Api from "../../../config/Api";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import GifImg from "../../../assets/images/loader.gif";
import DatePicker from "react-multi-date-picker";
import { useSelector } from "react-redux";
import CommonApi from "../../../config/CommonApi";
import { FaArrowLeft } from "react-icons/fa";
import Select from "react-select";
// import dayjs from "dayjs";

const Sdrf1 = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const admin = useSelector((state) => JSON.parse(state.authentication.admin));
  const { id, isEdit } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [centerList, setCenterList] = useState([]);
  const [detailData, setDetailData] = useState([]);
  const [policeStationList, setPoliceStationList] = useState([]);
  const [adminCircleList, setAdminCircleList] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  const [applicationFound, setApplicationFound] = useState({
    load: false,
    isFound: false,
    isIdFound: "",
    message: "",
  });

  const [ifscFound, setIfscFound] = useState({
    isloading: false,
    isFound: false,
    ifscData: "",
    message: "",
  });

  const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
  console.log("application founds", applicationFound);
  const checkApplication = async (e) => {
    try {
      setApplicationFound((prev) => ({ ...prev, load: true }));
      const body = {};
      if ((admin?.role?.role_name).toLowerCase().includes("dpa")) {
        body.application_id = `${admin?.district[0]?.district_code}/${e.target.value}`;
      } else {
        body.application_id = e.target.value;
      }

      // console.log(body);
      const res = await Api("check_application_id", "POST", body, true);
      console.log(res.data);

      if (res.data.status === 1) {
        setApplicationFound({
          load: false,
          isFound: true,
          isIdFound: true,
          message: res.data.message,
        });
        const districtRes = await getDistrict(res?.data?.data?.state?.id);
        const centerRes = await getCenter(res?.data?.data?.district?.id);
        await getAdminCricle(res?.data?.data?.district?.id);
        await getPoliceStation(res?.data?.data?.district?.id);
        if (districtRes && centerRes) {
          setValue("state_id", res?.data?.data?.state?.id);
          setValue("district_id", res?.data?.data?.district?.id);
        }
        setIsLoading(false);
      } else {
        setApplicationFound({
          load: false,
          isFound: true,
          isIdFound: false,
          message: res.data.message,
        });
        setIsLoading(false);
      }
    } catch (error) {
      // console.log(error);
      // toast.error(error.data.message, {
      //   position: toast.POSITION.BOTTOM_RIGHT,
      // });
    }
  };

  const style = {
    control: (base, state) => ({
      ...base,
      border: state?.isFocused ? "1px solid #224b8b" : "1px solid #ddd",
      boxShadow: "none",
      backgroundColor: state?.isFocused ? "" : "#fbfbfb",
      "&:hover": {
        borderColor: "#224b8b",
      },
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state?.isSelected ? "#224b8b" : "",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#224b8b",
      },
    }),
  };

  //get state
  const getStates = async () => {
    try {
      // setIsFetching(true);
      const res = await Api("get-state");
      if (res?.data?.status === 1) {
        setStateList(res?.data?.states);
        // setIsFetching(false);
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // setIsFetching(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //get state

  //getDistrict
  const getDistrict = async (id) => {
    try {
      setIsFetching(true);
      const res = await Api(`get-district/${id}`);
      // console.log(res);
      if (res?.data?.status === 1) {
        setDistrictList(res?.data?.district);
        setIsFetching(false);
        return true;
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsFetching(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //getDistrict

  //get Center
  const getCenter = async (id) => {
    try {
      setIsFetching(true);
      const body = {
        district_id: id,
      };
      const res = await Api("center_list", "POST", body);
      // console.log(res);
      if (res.data.status === 1) {
        setCenterList(res.data.center);
        setIsFetching(false);
        return true;
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsFetching(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //get Center

  //inputChange
  const inputChange = async (e) => {
    if (e.target.value) {
      if (e.target.name === "state_id") {
        getDistrict(e.target.value);
        // setValue([{ district_id: "" }, { center_id: "" }]);
        setValue("district_id", "");
        setValue("center_id", "");
      } else if (e.target.name === "district_id") {
        getCenter(e.target.value);
        setValue("center_id", "");
      }
    }
  };
  //inputChange

  //getApplication
  const getApplication = async (id) => {
    try {
      setIsFetching(true);
      // setIsLoading(true);
      const body = {
        id,
      };
      const res = await Api("view_applicant_details", "POST", body);
      // console.log(res.data);
      if (res.data.status === 1) {
        setDetailData(res.data.ApplicantDetails);
        let districtRes = await getDistrict(
          res.data.ApplicantDetails.Details.state_id
        );
        let centerRes = await getCenter(
          res.data.ApplicantDetails.Details.district_id
        );
        await getAdminCricle(res.data.ApplicantDetails.Details.district_id);
        await getPoliceStation(res.data.ApplicantDetails.Details.district_id);
        if (districtRes && centerRes) {
          let data = res.data.ApplicantDetails.Details;
          let tempid;
          if ((admin?.role?.role_name).toLowerCase().includes("dpa")) {
            tempid = data?.application_id.split(
              `${admin?.district[0]?.district_code}/`
            )[1];
          } else {
            tempid = data?.application_id;
          }
          console.log(data);

          reset({
            application_id: tempid,
            applicant_name: data?.applicant_name,
            applicant_last_name: data?.applicant_last_name,
            father_name: data?.father_name,
            gender: data?.gender,
            dob: data?.dob,
            mobile: data?.mobile,
            alternative_mobile_no:
              data?.alternative_mobile_no != "null"
                ? data?.alternative_mobile_no
                : "",
            aadhar_no: data?.aadhar_no,
            id_proof_document_type: data?.id_proof_document_type,
            id_proof_no: data?.id_proof_no,
            permanent_address: data?.permanent_address,
            present_address: data?.present_address,
            applicant_permanent_state: data?.applicant_permanent_state,
            applicant_present_state: data?.applicant_present_state,
            village: data?.village,
            present_village: data?.present_village,
            town: data?.town,
            present_town: data?.present_town,
            is_applicant_outside_nagaland:
              data?.is_applicant_outside_nagaland === "1" ? "1" : false,
            // admin_circle: {
            //   label: data?.admin_circle,
            //   value: data?.admin_circle,
            // },
            // present_admin_circle: {
            //   label: data?.present_admin_circle,
            //   value: data?.present_admin_circle,
            // },
            // police_station: {
            //   label: data?.police_station,
            //   value: data?.police_station,
            // },
            // present_police_station: {
            //   label: data?.present_police_station,
            //   value: data?.present_police_station,
            // },
            admin_circle:
              data?.is_applicant_outside_nagaland === "1"
                ? data?.admin_circle
                : {
                    label: data?.admin_circle,
                    value: data?.admin_circle,
                  },
            present_admin_circle:
              data?.is_applicant_outside_nagaland === "1"
                ? data?.present_admin_circle
                : {
                    label: data?.present_admin_circle,
                    value: data?.present_admin_circle,
                  },
            police_station:
              data?.is_applicant_outside_nagaland === "1"
                ? data?.police_station
                : {
                    label: data?.police_station,
                    value: data?.police_station,
                  },
            present_police_station:
              data?.is_applicant_outside_nagaland === "1"
                ? data?.present_police_station
                : {
                    label: data?.present_police_station,
                    value: data?.present_police_station,
                  },
            pincode: data?.pincode,
            state_id: data?.state_id,
            district_id: data?.district_id,
            center_id: data?.center_id,
            account_number: data?.account_number,
            confirm_account_number: data?.account_number,
            account_holder_name: data?.account_holder_name,
            bankname: data?.bankname,
            ifsc: data?.ifsc,
            branch: data?.branch,
            is_bank_account_owner: data?.is_bank_account_owner,
            relationship_with_applicant: data?.relationship_with_applicant,
          });
          setIsFetching(false);
        }
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsFetching(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //getApplication

  //checkIFSC
  const checkIFSCDetails = async (text) => {
    setValue("ifsc", text.toUpperCase());
    try {
      let url = `https://ifsc.razorpay.com/${text}`;
      setIfscFound((prev) => ({ ...prev, isloading: true }));
      const res = await CommonApi(url, "GET", "", true);
      //   console.log('ifsc', res.data);
      if (res.status === 200) {
        setIfscFound({
          ifscData: res.data,
          isloading: false,
          isFound: true,
          message: "IFSC Code Found",
        });
        if (Boolean(res.data)) {
          setValue("bankname", res.data.BANK);
          setValue("branch", res.data.BRANCH);
        }
        setIsLoading(false);
      } else {
        setIfscFound({
          isloading: false,
          isFound: true,
          message: "Invalid IFSC Code",
        });
        setIsLoading(false);
      }
    } catch (error) {
      // console.error("Error in checkIFSCDetails:", error);
      setIfscFound({
        isloading: false,
        isFound: true,
        message: "IFSC code not found",
      });
    }
  };
  //checkIFSC

  useEffect(() => {
    if (location.key === "default") {
      toast.error("You don't have permission to access this page", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      navigate(-1);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getStates();
    // eslint-disable-next-line
  }, [reset]);

  useEffect(() => {
    if (isEdit) {
      getApplication(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  const onSubmit = async (d) => {
    try {
      // setIsLoading(true);

      if (isEdit) {
        //     // console.log(d);
        if (d?.account_number !== d?.confirm_account_number) {
          toast.error("Confirm account number does not match account number", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setIsLoading(false);
          return;
        }

        if (
          d?.applicant_default_img?.length > 0 &&
          !allowedTypes.includes(d?.applicant_default_img[0]?.type)
        ) {
          toast.error(
            "Invalid file type. Please select a JPEG, PNG, JPG image.",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
          setIsLoading(false);
          return;
        }

        const formData = new FormData();
        formData.append("id", detailData?.Details?.id);
        formData.append("applicant_name", d?.applicant_name);
        formData.append("applicant_last_name", d?.applicant_last_name);
        formData.append("father_name", d?.father_name);
        formData.append("gender", d?.gender);
        formData.append("dob", d?.dob);
        formData.append("mobile", d?.mobile);
        formData.append("alternative_mobile_no", d?.alternative_mobile_no);
        formData.append("aadhar_no", d?.aadhar_no);

        if (!!d?.id_proof_document_type) {
          formData.append("id_proof_document_type", d?.id_proof_document_type);
        }

        if (!!d?.id_proof_no) {
          formData.append("id_proof_no", d?.id_proof_no);
        }

        formData.append("applicant_default_img", d?.applicant_default_img[0]);
        formData.append("signature_image", d?.signature[0]);
        formData.append("aadhar_image", d?.aadhar[0]);

        formData.append("permanent_address", d?.permanent_address);
        formData.append("present_address", d?.present_address);
        formData.append(
          "is_applicant_outside_nagaland",
          d?.is_applicant_outside_nagaland === "1" ? 1 : 0
        );

        if (!!d?.village) {
          formData.append("village", d?.village);
        }

        if (!!d?.present_village) {
          formData.append("present_village", d?.present_village);
        }

        if (!!d?.applicant_permanent_state) {
          formData.append(
            "applicant_permanent_state",
            d?.is_applicant_outside_nagaland === "1"
            ? d?.applicant_permanent_state : ""
          );
        }

        if (!!d?.applicant_present_state) {
          formData.append(
            "applicant_present_state",
            d?.is_applicant_outside_nagaland === "1"
            ? d?.applicant_present_state : ""
          );
        }

        if (!!d?.town) {
          formData.append("town", d?.town);
        }

        if (!!d?.present_town) {
          formData.append("present_town", d?.present_town);
        }

        if (!!d.admin_circle) {
          formData.append(
            "admin_circle",
            d?.is_applicant_outside_nagaland === "1"
              ? d?.admin_circle
              : d?.admin_circle?.value
          );
        }
        if (!!d.present_admin_circle) {
          formData.append(
            "present_admin_circle",
            d?.is_applicant_outside_nagaland === "1"
              ? d?.present_admin_circle
              : d?.present_admin_circle?.value
          );
        }
        if (!!d.police_station) {
          formData.append(
            "police_station",
            d?.is_applicant_outside_nagaland === "1"
              ? d?.police_station
              : d?.police_station?.value
          );
        }
        if (!!d.present_police_station) {
          formData.append(
            "present_police_station",
            d?.is_applicant_outside_nagaland === "1"
              ? d?.present_police_station
              : d?.present_police_station?.value
          );
        }

        formData.append("pincode", d?.pincode);
        formData.append("state_id", d?.state_id);
        formData.append("district_id", d?.district_id);
        formData.append("center_id", d?.center_id);
        formData.append("ifsc", d?.ifsc);
        formData.append("account_number", d?.confirm_account_number);
        formData.append("bankname", d?.bankname);
        formData.append("branch", d?.branch);
        formData.append("is_bank_account_owner", d?.is_bank_account_owner);
        if (d?.is_bank_account_owner === "No") {
          formData.append("account_holder_name", d?.account_holder_name);
          formData.append(
            "relationship_with_applicant",
            d?.relationship_with_applicant
          );
          formData.append(
            "relation_proof",
            d?.relation_proof?.length ? d?.relation_proof[0] : ""
          );
        }
        else {
          formData.append(
            "account_holder_name",
            `${d?.applicant_name}${
              !!d?.applicant_last_name ? ` ${d.applicant_last_name}` : ""
            }`
          );
        }
        console.log("formData", Object.fromEntries(formData));
        console.log("Data", d);
        const res = await Api("update-application-first", "POST", formData);
        // console.log(res);
        if (res.data.status === 1) {
          setDetailData((prev) => ({
            ...prev,
            Details: res.data.data,
          }));

          navigate("/sdrf-2", {
            state: {
              id: res.data.data.id,
              isEdit: isEdit,
            },
          });

          setIsLoading(false);
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setIsLoading(false);
        }
      } else {
        if (!applicationFound?.isIdFound) {
          toast.error("Application ID is not found!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setIsLoading(false);
          return;
        }
        // if (!Boolean(ifscFound.ifscData)) {
        //   toast.error("Invalid IFSC Code", {
        //     position: toast.POSITION.BOTTOM_RIGHT,
        //   });
        //   setIsLoading(false);
        //   return;
        // }
        if (d?.account_number !== d?.confirm_account_number) {
          toast.error("Confirm account number does not match account number", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setIsLoading(false);
          return;
        }

        const formData = new FormData();

        if ((admin?.role?.role_name).toLowerCase().includes("dpa")) {
          formData.append(
            "application_id",
            `${admin?.district[0]?.district_code}/${d?.application_id}`
          );
        } else {
          formData.append("application_id", d?.application_id);
        }

        formData.append("applicant_name", d?.applicant_name);
        formData.append("applicant_last_name", d?.applicant_last_name);
        formData.append("father_name", d?.father_name);
        formData.append("gender", d?.gender);
        formData.append("dob", d?.dob);
        formData.append("mobile", d?.mobile);
        formData.append("alternative_mobile_no", d?.alternative_mobile_no);
        formData.append("aadhar_no", d?.aadhar_no);

        if (!!d.id_proof_document_type) {
          formData.append("id_proof_document_type", d?.id_proof_document_type);
        }

        if (!!d?.id_proof_no) {
          formData.append("id_proof_no", d?.id_proof_no);
        }

        formData.append("applicant_default_img", d?.applicant_default_img[0]);
        formData.append("signature_image", d?.signature[0]);
        formData.append("aadhar_image", d?.aadhar[0]);

        formData.append("permanent_address", d?.permanent_address);
        formData.append("present_address", d?.present_address);
        formData.append(
          "is_applicant_outside_nagaland",
          d?.is_applicant_outside_nagaland === "1" ? 1 : 0
        );
        if (!!d.village) {
          formData.append("village", d?.village);
        }
        if (!!d.present_village) {
          formData.append("present_village", d?.present_village);
        }
        if (!!d.applicant_permanent_state) {
          formData.append(
            "applicant_permanent_state",
            d?.applicant_permanent_state
          );
        }
        if (!!d.applicant_present_state) {
          formData.append(
            "applicant_present_state",
            d?.applicant_present_state
          );
        }
        if (!!d.town) {
          formData.append("town", d?.town);
        }
        if (!!d.present_town) {
          formData.append("present_town", d?.present_town);
        }
        if (!!d.admin_circle) {
          formData.append(
            "admin_circle",
            d?.is_applicant_outside_nagaland === "1"
              ? d?.admin_circle
              : d?.admin_circle?.value
          );
        }
        if (!!d.present_admin_circle) {
          formData.append(
            "present_admin_circle",
            d?.is_applicant_outside_nagaland === "1"
              ? d?.present_admin_circle
              : d?.present_admin_circle?.value
          );
        }
        if (!!d.police_station) {
          formData.append(
            "police_station",
            d?.is_applicant_outside_nagaland === "1"
              ? d?.police_station
              : d?.police_station?.value
          );
        }
        if (!!d.present_police_station) {
          formData.append(
            "present_police_station",
            d?.is_applicant_outside_nagaland === "1"
              ? d?.present_police_station
              : d?.present_police_station?.value
          );
        }
        formData.append("pincode", d?.pincode);
        formData.append("state_id", d?.state_id);
        formData.append("district_id", d?.district_id);
        formData.append("center_id", d?.center_id);
        formData.append("ifsc", d?.ifsc);
        formData.append("account_number", d?.confirm_account_number);
        formData.append("bankname", d?.bankname);
        formData.append("branch", d?.branch);
        formData.append("is_bank_account_owner", d?.is_bank_account_owner);

        if (d?.is_bank_account_owner === "No") {
          formData.append("account_holder_name", d?.account_holder_name);
          formData.append(
            "relationship_with_applicant",
            d?.relationship_with_applicant
          );
          formData.append(
            "relation_proof",
            d?.relation_proof?.length ? d?.relation_proof[0] : ""
          );
        } else {
          formData.append(
            "account_holder_name",
            `${d?.applicant_name}${
              !!d?.applicant_last_name ? ` ${d.applicant_last_name}` : ""
            }`
          );
        }
        // console.log('dd', Object.fromEntries(formData.entries()));
        // return

        const res = await Api("save-application-first", "POST", formData);
        // console.log(res);
        if (res.data.status === 1) {
          setIsLoading(false);
          navigate("/sdrf-2", {
            state: {
              id: res.data.data,
            },
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setIsLoading(false);
        }
      }
    } catch (error) {
      // console.warn(error);
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const setSamePermanentAdd = (e) => {
    if (e.target.checked) {
      setValue("applicant_present_state", watch("applicant_permanent_state"));
      setValue("present_village", watch("village"));
      setValue("present_address", watch("permanent_address"));
      setValue("present_town", watch("town"));
      setValue("present_admin_circle", watch("admin_circle"));
      setValue("present_police_station", watch("police_station"));
    } else {
      setValue("applicant_present_state", "");
      setValue("present_village", "");
      setValue("present_address", "");
      setValue("present_town", "");
      setValue("present_admin_circle", "");
      setValue("present_police_station", "");
    }
  };

  const policeStationOption = (data) => {
    return data?.map((item) => {
      return {
        label: item.name,
        value: item.name,
        ...item,
      };
    });
  };

  const adminCircleOptions = (data) => {
    return data?.map((item) => {
      return {
        label: item.name,
        value: item.name,
        ...item,
      };
    });
  };

  const getPoliceStation = async (id) => {
    try {
      // setIsLoading(true);
      const url = `police_station_get/${id}`;
      const res = await Api(url);
      if (Number(res.data.status) == 1) {
        setPoliceStationList(policeStationOption(res.data.PoliceStation));
        // setIsLoading(false);
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const getAdminCricle = async (id) => {
    try {
      setIsLoading(true);
      const url = `admin_circle_get/${id}`;
      const res = await Api(url);
      if (Number(res.data.status) === 1) {
        setAdminCircleList(adminCircleOptions(res.data.AdminCircle));
        setIsLoading(false);
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      // setIsChecked(!isChecked);
      setValue(
        "admin_circle",
        !!watch("admin_circle") && !!Object.values(watch("admin_circle")).length
          ? watch("admin_circle")?.value
          : ""
      );
      setValue(
        "applicant_permanent_state",
        !!watch("applicant_permanent_state") && !!(watch("applicant_permanent_state")).length
          ? watch("applicant_permanent_state")
          : ""
      );
      setValue(
        "applicant_present_state",
        !!watch("applicant_present_state") && !!(watch("applicant_present_state")).length
          ? watch("applicant_present_state")
          : ""
      );
      setValue(
        "police_station",
        !!watch("police_station") &&
          !!Object.values(watch("police_station")).length
          ? watch("police_station")?.value
          : ""
      );
      setValue(
        "present_admin_circle",
        !!watch("present_admin_circle") &&
          !!Object.values(watch("present_admin_circle")).length
          ? watch("present_admin_circle")?.value
          : ""
      );
      setValue(
        "present_police_station",
        !!watch("present_police_station") &&
          !!Object.values(watch("present_police_station")).length
          ? watch("present_police_station")?.value
          : ""
      );
    } else {
      setValue(
        "admin_circle",
        !!detailData?.Details?.admin_circle
          ? {
              label: detailData?.Details?.admin_circle,
              value: detailData?.Details?.admin_circle,
            }
          : ""
      );
      setValue(
        "police_station",
        !!detailData?.Details?.police_station
          ? {
              label: detailData?.Details?.police_station,
              value: detailData?.Details?.police_station,
            }
          : ""
      );
      setValue(
        "present_admin_circle",
        !!detailData?.Details?.present_admin_circle
          ? {
              label: detailData?.Details?.present_admin_circle,
              value: detailData?.Details?.present_admin_circle,
            }
          : ""
      );
      setValue(
        "present_police_station",
        !!detailData?.Details?.present_police_station
          ? {
              label: detailData?.Details?.present_police_station,
              value: detailData?.Details?.present_police_station,
            }
          : ""
      );
    }
  };

  useEffect(() => {
    // getPoliceStation();
    // getAdminCricle();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={`loaderoverlay ${isFetching && "show"}`}>
        <div>
          <img src={GifImg} alt="Loader" />
          <p>Loading...</p>
        </div>
      </div>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="d-flex justify-content-between mb-4">
          <div>
            <button className="btn btn-primary" onClick={() => navigate(-1)}>
              <FaArrowLeft /> Back
            </button>
          </div>
          <div className="">
            <h5 className="text-dark">Personal Details</h5>
          </div>
        </div>
        <div className="steps_row mb-4">
          <ul>
            <li className="steps_box active">
              <div>
                <span className="steps_circle"></span>
                <p>Personal Details</p>
              </div>
            </li>
            <li className="steps_box">
              <div>
                <span className="steps_circle"></span>
                <p>Damage Report</p>
              </div>
            </li>
            <li className="steps_box">
              <div>
                <span className="steps_circle"></span>
                <p>Authentication</p>
              </div>
            </li>
          </ul>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            <div className="row g-3">
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <label className="form-label">
                  Application ID <span className="text-danger fw-bold">*</span>
                </label>
                <div className="position-relative">
                  <div className="input-group">
                    {(admin?.role?.role_name).toLowerCase() === "dpa" &&
                      !!admin?.district[0]?.district_code && (
                        <span className="input-group-text">
                          {admin?.district[0]?.district_code}/
                        </span>
                      )}

                    <input
                      type="text"
                      className="form-control"
                      {...register("application_id", {
                        required: true,
                        onChange: (e) => checkApplication(e),
                      })}
                      disabled={isEdit || isLoading}
                    />
                    {applicationFound.load && (
                      <div className="textbox_loader">
                        <img src={GifImg} alt="Loader" />
                      </div>
                    )}
                  </div>
                </div>
                {applicationFound.isFound && (
                  <div className="mt-1">
                    <span
                      className={`badge ${
                        applicationFound.isIdFound ? "bg-success" : "bg-danger"
                      }`}
                    >
                      {applicationFound.message}
                    </span>
                  </div>
                )}
                {errors.application_id && (
                  <div className="error_line">Application ID is required</div>
                )}
              </div>

              <div className="col-xl-3 col-lg-4 col-sm-6">
                <label className="form-label">
                  First Name <span className="text-danger fw-bold">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("applicant_name", { required: true })}
                  disabled={isLoading}
                />
                {errors.applicant_name && (
                  <div className="error_line">First Name is required</div>
                )}
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <label className="form-label">
                  Last Name <span className="text-danger fw-bold">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("applicant_last_name", { required: true })}
                  disabled={isLoading}
                />
                {errors.applicant_last_name && (
                  <div className="error_line">Last Name is required</div>
                )}
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <label className="form-label">
                  Father's Name <span className="text-danger fw-bold">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("father_name", { required: true })}
                  disabled={isLoading}
                />
                {errors.father_name && (
                  <div className="error_line">Father's Name is required</div>
                )}
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <label className="form-label">
                  Gender <span className="text-danger fw-bold">*</span>
                </label>
                <select
                  className="form-select"
                  {...register("gender", { required: true })}
                  disabled={isLoading}
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Others">Others</option>
                </select>
                {errors.gender && (
                  <div className="error_line">Gender is required</div>
                )}
              </div>

              <div className="col-xl-3 col-lg-4 col-sm-6">
                <label className="form-label">
                  Date of Birth <span className="text-danger fw-bold">*</span>
                </label>
                <Controller
                  control={control}
                  name="dob"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      inputClass="form-control"
                      placeholder="DD-MM-YYYY"
                      maxDate={new Date()}
                      format="DD-MM-YYYY"
                      editable={true}
                      value={field.value || ""}
                      onChange={(date) => {
                        field.onChange(date?.format?.("DD-MM-YYYY"));
                      }}
                      disabled={isLoading}
                    />
                  )}
                />
                {errors.dob && (
                  <div className="error_line">Date of Birth is required</div>
                )}
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <label className="form-label">
                  Mobile No. <span className="text-danger fw-bold">*</span>
                </label>
                <input
                  placeholder="Enter 10 digits mobile number only"
                  type="tel"
                  className="form-control"
                  {...register("mobile", {
                    required: "Mobile Number is required",
                    pattern: {
                      value: /^[6-9]\d{9}$/,
                      message: "Mobile Number is invalid",
                    },
                  })}
                  disabled={isLoading}
                />
                {errors.mobile && (
                  <div className="error_line">{errors.mobile.message}</div>
                )}
              </div>

              <div className="col-xl-3 col-lg-4 col-sm-6">
                <label className="form-label">Alternative Mobile No.</label>
                <input
                  placeholder="Enter 10 digits mobile number only"
                  type="tel"
                  className="form-control"
                  {...register("alternative_mobile_no", {
                    pattern: {
                      value: /^[6-9]\d{9}$/,
                      message: "Alternative Mobile Number is invalid",
                    },
                  })}
                  disabled={isLoading}
                />
                {errors.alternative_mobile_no && (
                  <div className="error_line">
                    {errors.alternative_mobile_no.message}
                  </div>
                )}
              </div>

              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <label className="form-label">
                      Applicant Passport Photo{" "}
                      <span className="text-danger fw-bold">*</span>
                    </label>
                  </div>
                  {detailData?.Details?.applicant_default_img && (
                    <div className="flex-shrink-0 ms-3">
                      <a
                        target="_blank"
                        href={detailData?.Details?.applicant_default_img}
                        rel="noreferrer"
                        className="badge bg-primary text-white"
                      >
                        View Image
                      </a>
                    </div>
                  )}
                </div>

                <input
                  type="file"
                  className="form-control"
                  {...register("applicant_default_img", {
                    required: isEdit ? false : "Applicant Photo is required",
                    validate: (value) =>
                      isEdit
                        ? true
                        : allowedTypes.includes(value[0]?.type) ||
                          "Invalid file type. Please select a JPEG, PNG, JPG image.",
                  })}
                  disabled={isLoading}
                />
                {errors.applicant_default_img && (
                  <div className="error_line">
                    {errors.applicant_default_img.message}
                  </div>
                )}

                {/* <input
                  type="file"
                  className="form-control"
                  {...register("applicant_default_img", {
                    required: isEdit ? false : true,
                    validate: {
                      validFileType: (value) => {
                        if (!value) return true; // Allow empty value
                        const allowedTypes = [
                          "image/jpeg",
                          "image/png",
                          "image/jpg",
                        ];
                        return allowedTypes.includes(value[0].type);
                      },
                    },
                  })}
                  disabled={isLoading}
                />
                {errors.applicant_default_img &&
                  errors.applicant_default_img.type === "required" && (
                    <div className="error_line">Image is required</div>
                  )}
                {errors.applicant_default_img &&
                  errors.applicant_default_img.type === "validFileType" && (
                    <div className="error_line">
                      Invalid file type. Please select a JPEG, PNG, JPG image.
                    </div>
                  )} */}
              </div>

              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <label className="form-label">
                      Applicant Signature Photo{" "}
                      {/* <span className="text-danger fw-bold">*</span> */}
                    </label>
                  </div>
                  {detailData?.Details?.signature_image && (
                    <div className="flex-shrink-0 ms-3">
                      <a
                        target="_blank"
                        href={detailData?.Details?.signature_image}
                        rel="noreferrer"
                        className="badge bg-primary text-white"
                      >
                        View Image
                      </a>
                    </div>
                  )}
                </div>
                <input
                  type="file"
                  className="form-control"
                  {...register("signature")}

                //   {...register("signature", {
                //     required: isEdit ? false : "Signature Photo is required",
                //     validate: (value) =>
                //       isEdit
                //         ? true
                //         : allowedTypes.includes(value[0]?.type) ||
                //           "Invalid file type. Please select a JPEG, PNG, JPG image.",
                //   })}
                  disabled={isLoading}
                />
                {/* {errors.signature && (
                  <div className="error_line">{errors.signature.message}</div>
                )} */}
              </div>

              <div className="col-xl-3 col-lg-4 col-sm-6"></div>
              <div className="col-xl-3 col-lg-4 col-sm-6"></div>

              <div className="col-xl-3 col-lg-4 col-sm-6">
                <label className="form-label">
                  Aadhaar No. <span className="text-danger fw-bold">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("aadhar_no", {
                    required: "Aadhar No. is required",
                    pattern: {
                      value:
                        /(^[0-9]{4}[0-9]{4}[0-9]{4}$)|(^[0-9]{4}\s[0-9]{4}\s[0-9]{4}$)|(^[0-9]{4}-[0-9]{4}-[0-9]{4}$)/,
                      message: "Aadhar No. is invalid",
                    },
                  })}
                  disabled={isLoading}
                />
                {errors.aadhar_no && (
                  <div className="error_line">{errors.aadhar_no.message}</div>
                )}
              </div>
              {/* <div className="col-xl-3 col-lg-4 col-sm-6">
                <label className="form-label">Select Document</label>
                <select
                  className="form-select"
                  {...register("id_proof_document_type", {
                    onChange: (e) => {
                      setValue(
                        "id_proof_no",
                        !e.target.value ? "" : watch("id_proof_no")
                      );
                    },
                  })}
                  disabled={isLoading}
                >
                  <option value="">Select Document</option>
                  <option value="Driving Licence">Driving Licence</option>
                  <option value="Pan Card">Pan Card</option>
                  <option value="Passport">Passport</option>
                  <option value="Ration Card">Ration Card</option>
                  <option value="Voter Id Card">Voter Id Card</option>
                  <option value="NOC">NOC</option>
                  <option value="Residential Proof">Residential Proof</option>
                  <option value="Electricity Bill">Electricity Bill</option>
                  <option value="LPG Connection Passbook">
                    LPG Connection Passbook
                  </option>
                  <option value="Bank Passbook">Bank Passbook</option>
                </select>
                {errors.id_proof_document_type && (
                  <div className="error_line">Document Type is required</div>
                )}
              </div> */}
              {/* {!!watch("id_proof_document_type") && (
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <label className="form-label">ID Proof No.</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("id_proof_no", {
                      required: "ID Proof No. is required",
                    })}
                    disabled={isLoading}
                  />
                  {errors.id_proof_no && (
                    <div className="error_line">
                      {errors.id_proof_no.message}
                    </div>
                  )}
                </div>
              )} */}

              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <label className="form-label">
                      Aadhaar Image Upload{" "}
                      <span className="text-danger fw-bold">*</span>
                    </label>
                  </div>
                  {detailData?.Details?.aadhar_image && (
                    <div className="flex-shrink-0 ms-3">
                      <a
                        target="_blank"
                        href={detailData?.Details?.aadhar_image}
                        rel="noreferrer"
                        className="badge bg-primary text-white"
                      >
                        View Image
                      </a>
                    </div>
                  )}
                </div>
                <input
                  type="file"
                  className="form-control"
                  {...register("aadhar", {
                    required: isEdit ? false : "Aadhar image is required",
                    validate: (value) =>
                      isEdit
                        ? true
                        : allowedTypes.includes(value[0]?.type) ||
                          "Invalid file type. Please select a JPEG, PNG, JPG image.",
                  })}
                  disabled={isLoading}
                />
                {errors.aadhar && (
                  <div className="error_line">{errors.aadhar.message}</div>
                )}
              </div>
              {/* <div>
                <input type="checkbox" onChange={handleCheckboxChange} />
              </div> */}
              <div className="col-12">
                <div className="form-check mt-2 small">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="1"
                    {...register("is_applicant_outside_nagaland", {
                      onChange: (e) => handleCheckboxChange(e),
                    })}
                    //   onChange={handleCheckboxChange}
                    id="is_applicant_outside_nagaland"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="is_applicant_outside_nagaland"
                  >
                    If the applicant's permanent address is outside Nagaland.
                  </label>
                </div>
              </div>
              {/* {console.log(
                "is_applicant_outside_nagaland",
                watch("is_applicant_outside_nagaland")
              )} */}
              {watch("is_applicant_outside_nagaland") ? (
                <>
                  <div className="col-12">
                    <div className="border border-2 p-3">
                      <div className="row g-4">
                        <div className="col-md-6 border-md-0 border-end border-2">
                          <h4>Permanent Address</h4>
                          <div className="row g-3">
                            <div className="col-sm-6">
                              <label className="form-label">
                                Address{" "}
                                <span className="text-danger fw-bold">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                {...register("permanent_address", {
                                  required: true,
                                })}
                                disabled={isLoading}
                              />
                              {errors.permanent_address && (
                                <div className="error_line">
                                  Permanent Address is required
                                </div>
                              )}
                            </div>
                            <div className="col-sm-6">
                              <label className="form-label">
                                Village/Colony/Ward
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                {...register("village")}
                                disabled={isLoading}
                              />
                            </div>
                            <div className="col-sm-6">
                              <label className="form-label">
                                Applicant Permanent State
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                {...register("applicant_permanent_state")}
                                disabled={isLoading}
                              />
                            </div>
                            <div className="col-sm-6">
                              <label className="form-label">City/Town
                              <span className="text-danger fw-bold">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                {...register("town", {
                                    required: true,
                                  })}
                                disabled={isLoading}
                              />

                               {errors.town && (
                                <div className="error_line">
                                  City is required
                                </div>
                              )}
                            </div>
                            <div className="col-sm-6">
                              <label className="form-label">
                                Admin Circle/Sub-division
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                {...register("admin_circle")}
                                disabled={isLoading}
                              />
                            </div>

                            <div className="col-sm-6">
                              <label className="form-label">
                                Police Station
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                {...register("police_station")}
                                disabled={isLoading}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <h4>Present Address</h4>
                          <div className="row g-3">
                            <div className="col-12">
                              <div className="form-check mt-2 small">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  {...register("same_as_permanent")}
                                  onChange={setSamePermanentAdd}
                                  id="same_as_permanent"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="same_as_permanent"
                                >
                                  Same As Permanent Address.
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <label className="form-label">
                                    Address
                                    <span className="text-danger fw-bold">
                                      *
                                    </span>
                                  </label>
                                </div>
                                <div className="flex-shrink-0 ms-3"></div>
                              </div>

                              <input
                                type="text"
                                className="form-control"
                                {...register("present_address", {
                                  required: true,
                                  onChange: (e) => {
                                    setValue("same_as_permanent", false);
                                  },
                                })}
                                disabled={isLoading}
                              />
                              {errors.present_address && (
                                <div className="error_line">
                                  Present Address is required
                                </div>
                              )}
                            </div>
                            <div className="col-sm-6">
                              <label className="form-label">
                                Village/Colony/Ward
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                {...register("present_village")}
                                disabled={isLoading}
                              />
                            </div>
                            <div className="col-sm-6">
                              <label className="form-label">
                                Applicant Present State
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                {...register("applicant_present_state")}
                                disabled={isLoading}
                              />
                            </div>
                            <div className="col-sm-6">
                              <label className="form-label">City/Town
                              <span className="text-danger fw-bold">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                {...register("present_town", {
                                    required: true,
                                })}
                                disabled={isLoading}
                              />
                              {errors.present_town && (
                                <div className="error_line">
                                  City is required
                                </div>
                              )}
                            </div>
                            <div className="col-sm-6">
                              <label className="form-label">
                                Admin Circle/Sub-division
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                {...register("present_admin_circle")}
                                disabled={isLoading}
                              />
                            </div>

                            <div className="col-sm-6">
                              <label className="form-label">
                                Police Station
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                {...register("present_police_station")}
                                disabled={isLoading}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-12">
                    <div className="border border-2 p-3">
                      <div className="row g-4">
                        <div className="col-md-6 border-md-0 border-end border-2">
                          <h4>Permanent Address</h4>
                          <div className="row g-3">
                            <div className="col-sm-6">
                              <label className="form-label">
                                Address{" "}
                                <span className="text-danger fw-bold">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                {...register("permanent_address", {
                                  required: true,
                                })}
                                disabled={isLoading}
                              />
                              {errors.permanent_address && (
                                <div className="error_line">
                                  Permanent Address is required
                                </div>
                              )}
                            </div>
                            {/* <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <label className="form-label">
                      Present Address
                      <span className="text-danger fw-bold">*</span>
                    </label>
                  </div>
                  <div className="flex-shrink-0 ms-3"></div>
                </div>

                <input
                  type="text"
                  className="form-control"
                  {...register("present_address", {
                    required: true,
                    onChange: (e) => {
                      setValue("same_as_permanent", false);
                    },
                  })}
                  disabled={isLoading}
                />
                <div className="form-check mt-2 small">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    {...register("same_as_permanent", {
                      onChange: (e) => {
                        e.target.checked
                          ? setValue(
                              "present_address",
                              watch("permanent_address")
                            )
                          : setValue("present_address", "");
                      },
                    })}
                    id="same_as_permanent"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="same_as_permanent"
                  >
                    Same As Permanent Address.
                  </label>
                </div>
                {errors.present_address && (
                  <div className="error_line">Present Address is required</div>
                )}
              </div> */}
                            <div className="col-sm-6">
                              <label className="form-label">
                                Village/Colony/Ward
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                {...register("village")}
                                disabled={isLoading}
                              />
                              {/* {errors.village && (
                  <div className="error_line">{errors.village.message}</div>
                )} */}
                            </div>
                            <div className="col-sm-6">
                              <label className="form-label">City/Town
                              <span className="text-danger fw-bold">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                {...register("town", {
                                    required: true,
                                })}
                                disabled={isLoading}
                              />
                              {errors.town && (
                                <div className="error_line">
                                  City is required
                                </div>
                              )}
                              {/* {errors.town && (
                  <div className="error_line">{errors.town.message}</div>
                )} */}
                            </div>
                            {/* <div className="col-sm-6">
                          <label className="form-label">
                            Admin Circle/Sub-division
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            {...register("admin_circle")}
                            disabled={isLoading}
                          /> */}
                            {/* {errors.admin_circle && (
                                <div className="error_line">
                                  {errors.admin_circle.message}
                                </div>
                              )} */}
                            {/* </div> */}

                            <div className="col-sm-6 col-md-6">
                              <label className="form-label">
                                Admin Circle/Sub-division
                                {/* <span className="text-danger fw-bold">*</span> */}
                              </label>

                              <Controller
                                control={control}
                                // rules={{ required: true }}
                                onFocus={true}
                                name="admin_circle"
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    autoFocus={field.ref}
                                    // isDisabled={isLoading}
                                    styles={style}
                                    placeholder={"Admin Circle/Sub-division"}
                                    options={adminCircleList}
                                    onChange={(e) => {
                                      field.onChange(e);
                                    }}
                                  />
                                )}
                              />
                              {/* {errors.admin_circle && (
                              <div className="error_line">Admin Circle/Sub-division is required.</div>
                            )} */}
                            </div>

                            {/* <div className="col-sm-6">
                          <label className="form-label">Police Station</label>
                          <input
                            type="text"
                            className="form-control"
                            {...register("police_station")}
                            disabled={isLoading}
                          />
                          {errors.police_station && (
                              <div className="error_line">{errors.police_station.message}</div>
                            )}
                        </div> */}

                            <div className="col-sm-6 col-md-6">
                              <label className="form-label">
                                Police Station
                                {/* <span className="text-danger fw-bold">*</span> */}
                              </label>

                              <Controller
                                control={control}
                                // rules={{ required: true }}
                                onFocus={true}
                                name="police_station"
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    autoFocus={field.ref}
                                    // isDisabled={isLoading}
                                    styles={style}
                                    placeholder={"Select Police Station"}
                                    options={policeStationList}
                                    onChange={(e) => {
                                      field.onChange(e);
                                    }}
                                  />
                                )}
                              />
                              {/* {errors.police_station && (
                              <div className="error_line">Police Station is required.</div>
                            )} */}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <h4>Present Address</h4>
                          <div className="row g-3">
                            <div className="col-12">
                              <div className="form-check mt-2 small">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  {...register("same_as_permanent")}
                                  onChange={setSamePermanentAdd}
                                  id="same_as_permanent"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="same_as_permanent"
                                >
                                  Same As Permanent Address.
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <label className="form-label">
                                    Address
                                    <span className="text-danger fw-bold">
                                      *
                                    </span>
                                  </label>
                                </div>
                                <div className="flex-shrink-0 ms-3"></div>
                              </div>

                              <input
                                type="text"
                                className="form-control"
                                {...register("present_address", {
                                  required: true,
                                  onChange: (e) => {
                                    setValue("same_as_permanent", false);
                                  },
                                })}
                                disabled={isLoading}
                              />
                              {errors.present_address && (
                                <div className="error_line">
                                  Present Address is required
                                </div>
                              )}
                            </div>
                            <div className="col-sm-6">
                              <label className="form-label">
                                Village/Colony/Ward
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                {...register("present_village")}
                                disabled={isLoading}
                              />
                              {/* {errors.village && (
                  <div className="error_line">{errors.village.message}</div>
                )} */}
                            </div>
                            <div className="col-sm-6">
                              <label className="form-label">City/Town
                              <span className="text-danger fw-bold">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                {...register("present_town", {
                                    required: true,
                                })}
                                disabled={isLoading}
                              />
                              {errors.present_town && (
                                <div className="error_line">
                                  City is required
                                </div>
                              )}
                              {/* {errors.town && (
                  <div className="error_line">{errors.town.message}</div>
                )} */}
                            </div>
                            {/* <div className="col-sm-6">
                          <label className="form-label">
                            Admin Circle/Sub-division
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            {...register("present_admin_circle")}
                            disabled={isLoading}
                          /> */}
                            {/* {errors.admin_circle && (
                            <div className="error_line">
                              {errors.admin_circle.message}
                            </div>
                          )} */}
                            {/* </div> */}

                            <div className="col-sm-6 col-md-6">
                              <label className="form-label">
                                Admin Circle/Sub-division
                                {/* <span className="text-danger fw-bold">*</span> */}
                              </label>

                              <Controller
                                control={control}
                                // rules={{ required: true }}
                                onFocus={true}
                                name="present_admin_circle"
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    autoFocus={field.ref}
                                    // isDisabled={isLoading}
                                    styles={style}
                                    placeholder={"Admin Circle/Sub-division"}
                                    options={adminCircleList}
                                    onChange={(e) => {
                                      field.onChange(e);
                                    }}
                                  />
                                )}
                              />
                              {/* {errors.present_admin_circle && (
                              <div className="error_line">Admin Circle/Sub-division is required.</div>
                            )} */}
                            </div>

                            {/* <div className="col-sm-6">
                          <label className="form-label">Police Station</label>
                          <input
                            type="text"
                            className="form-control"
                            {...register("present_police_station")}
                            disabled={isLoading}
                          /> */}
                            {/* {errors.police_station && (
                              <div className="error_line">{errors.police_station.message}</div>
                            )} */}
                            {/* </div> */}

                            <div className="col-sm-6 col-md-6">
                              <label className="form-label">
                                Police Station
                                {/* <span className="text-danger fw-bold">*</span> */}
                              </label>
                              <Controller
                                control={control}
                                // rules={{ required: true }}
                                onFocus={true}
                                name="present_police_station"
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    autoFocus={field.ref}
                                    // isDisabled={isLoading}
                                    styles={style}
                                    placeholder={"Select Police Station"}
                                    options={policeStationList}
                                    onChange={(e) => {
                                      field.onChange(e);
                                    }}
                                  />
                                )}
                              />
                              {/* {errors.present_police_station && (
                              <div className="error_line"> Police Station is required.</div>
                            )} */}
                            </div>
                          </div>

                          {/* <div className="col-12">
                        <div className="form-check mt-2 small">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            {...register("same_as_permanent")}
                            onChange={setSamePermanentAdd}
                            id="same_as_permanent"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="same_as_permanent"
                          >
                            Same As Permanent Address.
                          </label>
                        </div>
                      </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="col-xl-3 col-lg-4 col-sm-6">
                <label className="form-label">
                  Pin Code <span className="text-danger fw-bold">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("pincode", {
                    required: "Pincode is required",
                    pattern: {
                      value: /^[1-9][0-9]{5}$/,
                      message: "Pincode is invalid",
                    },
                  })}
                  disabled={isLoading}
                />
                {errors.pincode && (
                  <div className="error_line">{errors.pincode.message}</div>
                )}
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <label className="form-label">
                  State <span className="text-danger fw-bold">*</span>
                </label>
                <select
                  className="form-control"
                  {...register("state_id", {
                    required: true,
                    onChange: (e) => inputChange(e),
                  })}
                  //   disabled={true}
                >
                  <option value="">Select State</option>
                  {stateList
                    .filter((state) => {
                      return state.status === "Active";
                    })
                    .map((state) => {
                      return (
                        <option value={state.id} key={state.id}>
                          {state.state_name}
                        </option>
                      );
                    })}
                </select>
                {errors.state_id && (
                  <div className="error_line">State is required</div>
                )}
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <label className="form-label">
                  District <span className="text-danger fw-bold">*</span>
                </label>
                <select
                  className="form-control"
                  {...register("district_id", {
                    required: true,
                    onChange: (e) => inputChange(e),
                  })}
                  disabled={true}
                >
                  <option value="">Select District</option>
                  {districtList
                    ?.filter((districts) => {
                      return districts.status === "Active";
                    })
                    .map((districts) => {
                      return (
                        <option value={districts?.id} key={districts?.id}>
                          {districts?.district_name}
                        </option>
                      );
                    })}
                </select>
                {errors.district_id && (
                  <div className="error_line">District is required</div>
                )}
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <label className="form-label">
                  Select DRPS Center{" "}
                  <span className="text-danger fw-bold">*</span>
                </label>
                <select
                  className="form-select"
                  {...register("center_id", { required: true })}
                  disabled={isLoading}
                >
                  <option value="">Select DRPS Center</option>
                  {centerList?.map((val, i) => {
                    return (
                      <option key={i} value={val.id}>
                        {`${val.center_name} (${val?.district?.district_name}, ${val?.state?.state_name})`}
                      </option>
                    );
                  })}
                </select>
                {errors.center_id && (
                  <div className="error_line">Center is required</div>
                )}
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <label className="form-label">
                  Name of the bank{" "}
                  <span className="text-danger fw-bold">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("bankname", { required: true })}
                  disabled={isLoading}
                />
                {errors.bankname && (
                  <div className="error_line">Bank Name is required</div>
                )}
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <label className="form-label">
                  IFSC Code <span className="text-danger fw-bold">*</span>
                </label>
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    {...register("ifsc", {
                      required: "IFSC Code is required",
                    //   pattern: {
                    //     // value: /^[A-Za-z]{4}\d{7}$/,
                    //     value: /[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/,
                    //     message: "IFSC Code is invalid",
                    //   },
                        onChange: (e) => {
                          checkIFSCDetails(e.target.value);
                        },
                    })}
                    disabled={isLoading}
                  />
                  {ifscFound.isloading && (
                    <div className="textbox_loader">
                      <img src={GifImg} alt="Loader" />
                    </div>
                  )}
                </div>
                {ifscFound.isFound && (
                  <div className="mt-1">
                    <span
                      className={`badge ${
                        Boolean(ifscFound.ifscData) ? "bg-success" : "bg-danger"
                      }`}
                    >
                      {ifscFound.message}
                    </span>
                  </div>
                )}
                {errors.ifsc && (
                  <div className="error_line">{errors.ifsc.message}</div>
                )}
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <label className="form-label">
                  Bank Account No.{" "}
                  <span className="text-danger fw-bold">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("account_number", {
                    required: "Bank A/C No. is required",
                    pattern: {
                      value: /^[0-9]{9,18}$/,
                      message: "Account No. is invalid",
                    },
                  })}
                  disabled={isLoading}
                />
                {errors.account_number && (
                  <div className="error_line">
                    {errors.account_number.message}
                  </div>
                )}
              </div>

              <div className="col-xl-3 col-lg-4 col-sm-6">
                <label className="form-label">
                  Confirm Bank Account No.{" "}
                  <span className="text-danger fw-bold">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("confirm_account_number", {
                    required: "Confirm Bank A/C No. is required",
                    pattern: {
                      value: /^[0-9]{9,18}$/,
                      message: "Confirm Account No. is invalid",
                    },
                  })}
                  disabled={isLoading}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                />
                {errors.confirm_account_number && (
                  <div className="error_line">
                    {errors.confirm_account_number.message}
                  </div>
                )}
              </div>

              {/* <div className="col-xl-3 col-lg-4 col-sm-6">
                <label className="form-label">
                  Name of the bank{" "}
                  <span className="text-danger fw-bold">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("bankname", { required: true })}
                  disabled={isLoading}
                />
                {errors.bankname && (
                  <div className="error_line">Bank Name is required</div>
                )}
              </div> */}

              <div className="col-xl-3 col-lg-4 col-sm-6">
                <label className="form-label">
                  Branch <span className="text-danger fw-bold">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("branch", { required: true })}
                  disabled={isLoading}
                />
                {errors.branch && (
                  <div className="error_line">Branch is required</div>
                )}
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <label className="form-label">
                  Is this your own account?{" "}
                  <span className="text-danger fw-bold">*</span>
                </label>
                <select
                  className="form-select"
                  {...register("is_bank_account_owner", {
                    required: true,
                  })}
                  disabled={isLoading}
                >
                  <option value="">Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
                {errors.is_bank_account_owner && (
                  <div className="error_line">This Field is required</div>
                )}
              </div>
              {watch("is_bank_account_owner") === "No" && (
                <>
                  <div className="col-12">
                    <div className="alert alert-warning m-0">
                      <p>
                        (In case the applicant doesn’t have an own account,
                        please provide details of a close relative.)
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-sm-6">
                    <label className="form-label">
                      Name of the account holder{" "}
                      <span className="text-danger fw-bold">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("account_holder_name", { required: true })}
                      disabled={isLoading}
                    />
                    {errors.account_holder_name && (
                      <div className="error_line">
                        Account holder name is required
                      </div>
                    )}
                  </div>
                  <div className="col-lg-4 col-sm-6">
                    <label className="form-label">
                      Relationship with the applicant
                      <span className="text-danger fw-bold">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("relationship_with_applicant", {
                        required: true,
                      })}
                      disabled={isLoading}
                    />
                    {errors.relationship_with_applicant && (
                      <div className="error_line">
                        Applicant Relationship is required
                      </div>
                    )}
                  </div>

                  <div className="col-lg-4 col-sm-12">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <label className="form-label">ID/Relation Proof
                        <span className="text-danger fw-bold">*</span>
                        </label>
                      </div>
                      {detailData?.Details?.relation_proof && (
                        <div className="flex-shrink-0 ms-3">
                          <a
                            target="_blank"
                            href={detailData?.Details?.relation_proof}
                            className="badge bg-primary text-white"
                            rel="noreferrer"
                          >
                            View Relation Proof
                          </a>
                        </div>
                      )}
                    </div>

                    <input
                      type="file"
                      className="form-control"
                      {...register("relation_proof", {
                        // required: isEdit ? false : true,
                        required: !detailData?.Details?.relation_proof,
                      })}
                      disabled={isLoading}
                    />
                    <div className="mt-1">
                      <p className="small">
                        (Certificate from competent authority to be produced)
                      </p>
                    </div>
                    {errors.relation_proof && (
                      <div className="error_line">
                        ID/Relation Proof is required
                      </div>
                    )}
                  </div>
                </>
              )}

              <div className="col-12">
                <div>
                  <input
                    type="submit"
                    value={`${isLoading ? "Submitting..." : "Next"}`}
                    className="btn btn-primary px-5"
                    disabled={isLoading}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Sdrf1;
