import React, { useEffect, useState } from "react";
import Api from "../../../config/Api";
import { toast } from "react-toastify";
// import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../Components/Widget/Loader";
import { FaUpload, FaTrashAlt } from "react-icons/fa";
import { BsFillPencilFill, BsEyeFill } from "react-icons/bs";
import StatusBadge from "../Application/Components/StatusBadge";
import ModalBox from "../../Components/Widget/ModalBox";

import Table from "../../Components/Widget/Table";
import TooltipWidget from "../../Components/Widget/TooltipWidget";

const ManageApplications = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [application, setApplication] = useState([]);
  const [applicationByAadhar, setApplicationByAadhar] = useState([]);
  const [isApplicationLoaded, setIsApplicationLoaded] = useState(false);
  const [filter, setFilter] = useState("");
  // const [currentStatus, setCurrentStatus] = useState('');
  // const admin = useSelector((state) => JSON.parse(state.authentication.admin));
  // console.log("cid", admin.id);

  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [deleteApplicationId, setDeleteApplicationId] = useState("");
  const [showApplicationByAadharModal, setShowApplicationByAadharModal] =
    useState(false);
  const [selectedAadharNo, setSelectedAadharNo] = useState("");
  const [selectedApplicationId, setSelectedApplicationId] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");

  const deleteModalShow = (id) => {
    setIsDeleteModalShow(true);
    setDeleteApplicationId(id);
  };
  const deleteModalClose = () => {
    setIsDeleteModalShow(false);
    setDeleteApplicationId("");
  };

  const applicationModelByAadharShow = (
    aadharNo,
    applicationId,
    district_id
  ) => {
    getApplicationByAadharNo(aadharNo, district_id);
    setShowApplicationByAadharModal(true);
    setSelectedAadharNo(aadharNo);
    setSelectedApplicationId(applicationId);
    setSelectedDistrict(district_id);
  };

  const applicationModelByAadharClose = (id) => {
    setShowApplicationByAadharModal(false);
    setDeleteApplicationId("");
  };

  //Application Get
  const getApplication = async (status_id) => {
    try {
      setIsLoading(true);
      const body = {
        status: status_id,
      };
      const res = await Api("application_list", "POST", body, true);
    //   console.log("payment-status", res.data.data);
      if (res.data.status === 1) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setApplication(res.data.data);
        setIsApplicationLoaded(true);
        setIsLoading(false);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //Application Get

  //Get Application By Aadhar No
  const getApplicationByAadharNo = async (aadhar_no, district_id) => {
    try {
      setIsLoading(true);
      const body = {
        aadhar_no: aadhar_no,
        district_id: district_id,
        status: [1, 2]
      };
      const res = await Api("view_application_by_aadhar_no", "POST", body, true);

      if (res.data.status === 1) {
        setApplicationByAadhar(res.data.data);
        setIsApplicationLoaded(true);
        setIsLoading(false);
      } else {
        toast.error('data not found', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //Get Application By Aadhar No

  //filter
  const searchInput = (e) => {
    setFilter(e.target.value);
  };

  const filtered = !filter
    ? application
    : application.filter(
        (application) =>
          application?.application_id
            ?.toLowerCase()
            .includes(filter.toLowerCase()) ||
          application?.applicant_name
            ?.toLowerCase()
            .includes(filter.toLowerCase()) ||
          application?.mobile?.toLowerCase().includes(filter.toLowerCase())
      );

  useEffect(() => {
    getApplication([1, 2]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterStatus = (e) => {
    if (!!e.target.value) {
      setIsApplicationLoaded(false);
      getApplication([e.target.value]);
    } else {
      setIsApplicationLoaded(false);
      getApplication([1, 2]);
    }
  };

  const deleteApplication = async () => {
    try {
      setIsLoading(true);
      const body = {
        id: deleteApplicationId,
      };
      // console.log(body);
      const res = await Api("delete_application", "POST", body, true);
      // console.log(res);
      if (res.data.status === 1) {
        getApplication([1]);
        deleteModalClose();
        setIsApplicationLoaded(false);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const columns = [
    {
      name: "#",
      selector: (props) => filtered.indexOf(props) + 1,
      sortable: true,
    },
    {
      name: "Application ID",
      selector: (row) => row.application_id,
      sortable: true,
      wrap: true,
    },
    {
      //   name: "Applicant Image",
      name: "Applicant Passport Photo",
      selector: (row) => (
        <img
          src={row.applicant_default_img}
          alt="Applicant"
          style={{
            height: "70px",
            width: "100px",
            objectFit: "cover",
          }}
        />
      ),
    },
    {
      name: "Applicant Info",
      selector: (row) => (
        <>
          <div className="mb-1">{row.applicant_name}</div>
          <div>{row.mobile}</div>
        </>
      ),
    },
    {
      name: "District",
      selector: (row) => row.district?.district_name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Aadhar No",
      selector: (row) => (
        <>
          {row.aadhar_no}
          <TooltipWidget title="View No Of Application By This Aadhaar number">
            {/* <Link
                        className="btn btn-warning btn-sm"
                        to={`/application/${window.btoa(row.id)}`}
                    >
                        <BsEyeFill />
                    </Link> */}
            <button
              className="btn ms-3 btn-primary btn-sm"
              type="button"
              onClick={() =>
                applicationModelByAadharShow(
                  row.aadhar_no,
                  row.id,
                  row.district_id
                )
              }
            >
              <BsEyeFill />
            </button>
          </TooltipWidget>
        </>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => {
        if (row?.payment_status === "R") {
          return (
            <>
              <StatusBadge status={row.status} />
              <span
                style={{
                  backgroundColor: "red",
                  color: "white",
                  padding: "4px 6px",
                  borderRadius: "12px",
                  fontSize: "11px",
                }}
              >
                Failed
              </span>
            </>
          );
        } else {
          return <StatusBadge status={row.status} />;
        }
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex gap-1">
          <TooltipWidget title="View SDRF">
            <Link
              //   target="_blank"
              className="btn btn-warning btn-sm"
              to={`/application/${window.btoa(row.id)}`}
            >
              <BsEyeFill />
            </Link>
          </TooltipWidget>
          <TooltipWidget title="Edit SDRF">
            <button
              className="btn btn-primary  btn-sm"
              onClick={() =>
                navigate("/sdrf-1", {
                  state: {
                    id: row.id,
                    isEdit: true,
                  },
                })
              }
            >
              <BsFillPencilFill />
            </button>
          </TooltipWidget>
          <TooltipWidget title="Delete SDRF">
            <button
              className="btn btn-danger btn-sm"
              type="button"
              onClick={() => deleteModalShow(row.id)}
            >
              <FaTrashAlt />
            </button>
          </TooltipWidget>
          {/* <TooltipWidget title="Upload Documents">
            <button
              className="btn btn-info  btn-sm"
              type="button"
              onClick={() => {
                navigate("/sdrf-documents", {
                  state: {
                    id: row.id,
                  },
                });
              }}
            >
              <FaUpload />
            </button>
          </TooltipWidget> */}
        </div>
      ),
      minWidth: "180px",
    },
  ];

  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="mb-3">
          <div className="row g-2">
            <div className="col-lg-6 col-md-4">
              <h5 className="text-dark">Manage Applications</h5>
            </div>
            <div className="col-lg-6 col-md-8">
              <div className="">
                <div className="row g-2">
                  <div className="col-sm-6 col-lg-4">
                    <div className="">
                      <select
                        className="form-select"
                        name="status"
                        onChange={(e) => filterStatus(e)}
                        disabled={isLoading}
                      >
                        <option value="">All Status</option>
                        <option value="1">Pending</option>
                        <option value="2">DPA Review</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-8">
                    <div className="">
                      <input
                        type="text"
                        placeholder="Search Application"
                        className="form-control"
                        value={filter}
                        onChange={searchInput}
                        disabled={isLoading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {isApplicationLoaded ? (
            <Table columns={columns} data={filtered} />
          ) : (
            <Loader />
          )}
        </div>
      </div>
      <ModalBox
        show={isDeleteModalShow}
        hide={deleteModalClose}
        title="Delete Application"
      >
        <div>
          <div>
            <div className="mb-3">
              <p className=" fs-5">
                Are you sure you want to delete this application?
              </p>
            </div>
            <div className="d-flex gap-2">
              <button
                className="btn btn-danger px-4"
                type="button"
                onClick={deleteApplication}
                disabled={isLoading}
              >
                Yes
              </button>
              <button
                className="btn btn-secondary px-4"
                type="button"
                onClick={deleteModalClose}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </ModalBox>

      <ModalBox
        show={showApplicationByAadharModal}
        hide={applicationModelByAadharClose}
        title="Number Of Application By The Same Aadhar No"
      >

        {/* <div>
        {applicationByAadhar.length > 0 ? (
            applicationByAadhar.map((app) => (
                <div key={app.id} className="mb-3 d-flex justify-content-evenly">
                    <p>Application Id: {app.application_id}
                    <Link to={`/application/${window.btoa(app.id)}`} className="ms-3 btn btn-sm btn-primary">
                        <BsEyeFill />
                    </Link>
                    </p>
                </div>
            ))
        ) : (
            <p>No applications found.</p>
        )}
       </div> */}

        <div>
        {applicationByAadhar.length > 0 ? (
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Application ID</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {applicationByAadhar.map((app) => (
                        <tr key={app.id}>
                            <td>{app.application_id}</td>
                            <td>
                                <Link to={`/application/${window.btoa(app.id)}`} className="btn btn-sm btn-primary">
                                    <BsEyeFill /> View
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        ) : (
            <p>No applications found.</p>
        )}
      </div>
      </ModalBox>
    </>
  );
};

export default ManageApplications;
