import React, { useEffect, useState } from "react";
import Api from "../../config/Api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-multi-date-picker";
import dayjs from "dayjs";
import { createSearchParams } from "react-router-dom";
import StatusBadge from "../SDRFManagement/Application/Components/StatusBadge";
import { FaRegFileExcel, FaRegFilePdf } from "react-icons/fa";
import TooltipWidget from "../Components/Widget/TooltipWidget";
import Table from "../Components/Widget/Table";
import Loader from "../Components/Widget/Loader";
import Status from "./Components/status.json";
import ModalBox from "../Components/Widget/ModalBox";


const GenerateReport = () => {
    const {
        control,
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        formState: { errors },
        // reset,
      } = useForm();
  const { handleSubmit: handleSubmit1, register: register1, reset, formState:{errors:errors1} } = useForm();
  // const navigate = useNavigate();
  const admin = useSelector((state) => JSON.parse(state.authentication.admin));
  const [isLoading, setIsLoading] = useState(false);
  // const [isFetching, setIsFetching] = useState(false);
  const [application, setApplication] = useState([]);
  const [applicationCount, setApplicationCount] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [isApplicationLoaded, setIsApplicationLoaded] = useState(false);
  const [filter, setFilter] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [currentDistrict, setCurrentDistrict] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [isExcelDownloadModalShow, setIsExcelDownloadModalShow] = useState(false);
  const [isPdfDownloadModalShow, setIsPdfDownloadModalShow] = useState(false);
  const [pdfNumberList, setPdfNumberList] = useState([]);

  const excelDownloadModalShow = () => {
    setIsExcelDownloadModalShow(true);
  }

  const excelDownloadModalclose = () => {
    setIsExcelDownloadModalShow(false);
  }

  const pdfDownloadModalShow = () => {
    setIsPdfDownloadModalShow(true);
  }

  const pdfDownloadModalclose = () => {
    setIsPdfDownloadModalShow(false);
  }

  const District = async (e) => {
    const res = await Api("get-district");
    setDistrictList(res?.data?.district);
    //console.log(res);
  };

  useEffect(() => {
    District();
  }, []);

  const onDownloadExcel = d => {
    downloadExcell(d?.range);
  }
  const onDownloadPdf = d => {
    downloadPdff(d?.range);
  }

  const downloadExcell = async (range) => {
    try {
      let start_no = range.split("-")[0];
      let end_no = range.split("-")[1];
      let district_id = currentDistrict ? currentDistrict : null;
      let start_date = currentDate?.start_date ? currentDate?.start_date : null;
      let end_date = currentDate?.end_date ? currentDate?.end_date : null;
      let baseUrl = process.env.REACT_APP_API_URL;
      let endpoint = "export_sdrf_report_per_district";
      let status = !!currentStatus ? `status[]=${currentStatus}` : null;
      let url;

      if (Boolean(currentDistrict)) {
        url = `${baseUrl}${endpoint}?district_id=${district_id}&start_no=${start_no}&${status}`;
      }
      else if (currentDate) {
        // console.log('currentDatefotexport', currentDate?.start_date, currentDate?.end_date);
        url = `${baseUrl}${endpoint}?start_date=${start_date}&end_date=${end_date}&start_no=${start_no}`;
        // console.log('url', url);

      } else {
        url = `${baseUrl}${endpoint}?${status}&start_no=${start_no}`;
      }

    //   let newObj =  {
    //     start_no,
    //     end_no,
    //   };
    //   Object.keys(getValues()).forEach((prop) => {
    //     if (Boolean(getValues()[prop])) {
    //       newObj[prop] = getValues()[prop];
    //     }
    //   });
    //   let searchparams = createSearchParams(newObj).toString();
    //   let url = `${baseUrl}${endpoint}${
    //     !!searchparams ? `?${searchparams}` : ""
    //   }`;

    //   console.log('urll',url);
      window.open(url, "_blank");
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  const downloadPdff = async (range) => {
    try {
      let start_no = range.split("-")[0];
      let end_no = range.split("-")[1];
      let district_id = currentDistrict ? currentDistrict : null;
      let start_date = currentDate?.start_date ? currentDate?.start_date : null;
      let end_date = currentDate?.end_date ? currentDate?.end_date : null;
      let baseUrl = process.env.REACT_APP_API_URL;
      let endpoint = `pdf-sdrfreport-per-district/${admin.id}`;
      let status = !!currentStatus ? `status[]=${currentStatus}` : null;
      let url;

      if (Boolean(currentDistrict)) {
        url = `${baseUrl}${endpoint}?district_id=${district_id}&start_no=${start_no}&${status}`;
      }
      else if (currentDate) {
        // console.log('currentDatefotexport', currentDate?.start_date, currentDate?.end_date);
        url = `${baseUrl}${endpoint}?start_date=${start_date}&end_date=${end_date}&start_no=${start_no}`;
        // console.log('url', url);

      } else {
        url = `${baseUrl}${endpoint}?${status}&start_no=${start_no}`;
      }

    //   console.log('urll',url);
      window.open(url, "_blank");
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //Application Get
  const getApplication = async (status_id, district_id) => {
    try {
      setIsLoading(true);
      const body = {};
      if (Boolean(status_id)) {
        body.status = status_id;
      }
      if (Boolean(district_id)) {
        body.district_id = district_id;
      }

      //console.log(body);
      const res = await Api("sdrf_report_per_district", "POST", body, true);
    //   console.log('count', res.data.count);
      if (res.data.status === 1) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setApplication(res.data.data);
        setApplicationCount(res.data.count);
        setIsApplicationLoaded(true);
        setIsLoading(false);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //Application Get

  //filter
  const searchInput = (e) => {
    setFilter(e.target.value);
  };

  const filtered = !filter
    ? application
    : application.filter(
        (application) =>
          application?.application_id
            ?.toLowerCase()
            .includes(filter.toLowerCase()) ||
          application?.applicant_name
            ?.toLowerCase()
            .includes(filter.toLowerCase()) ||
          application?.mobile?.toLowerCase().includes(filter.toLowerCase())
      );

  useEffect(() => {
    getApplication(currentStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterStatus = (e) => {
    if (!!e.target.value) {
      setIsApplicationLoaded(false);
      getApplication(e.target.value, currentDistrict);
      setCurrentStatus(e.target.value);
    } else {
      setIsApplicationLoaded(false);
      getApplication("", currentDistrict);
      setCurrentStatus("");
    }
  };

  const filterDistrict = (e) => {
    if (!!e.target.value) {
      setIsApplicationLoaded(false);
      getApplication(currentStatus, e.target.value);
      setCurrentDistrict(e.target.value);
      setValue("count_date_range", "");
    } else {
      setIsApplicationLoaded(false);
      getApplication(currentStatus);
      setCurrentDistrict("");
      setValue("count_date_range", "");
    }
  };

//   const downloadExcel = async () => {
//     try {
//       let baseUrl = process.env.REACT_APP_API_URL;
//       let endpoint = "export_sdrf_report_per_district";
//       let district_id = currentDistrict ? currentDistrict : null;
//       let start_date = currentDate?.start_date ? currentDate?.start_date : null;
//       let end_date = currentDate?.end_date ? currentDate?.end_date : null;

//       // let status = currentStatus?.length
//       //   ? currentStatus?.map((a) => `status[]=${a}`).join("&")
//       //   : null;
//       let status = !!currentStatus ? `status[]=${currentStatus}` : null;
//       let url;
//       if (Boolean(currentDistrict)) {
//         url = `${baseUrl}${endpoint}?district_id=${district_id}&${status}`;
//       }
//       else if (currentDate) {
//         console.log('currentDatefotexport', currentDate?.start_date, currentDate?.end_date);
//         url = `${baseUrl}${endpoint}?start_date=${start_date}&end_date=${end_date}`;
//         console.log('url', url);

//       } else {
//         url = `${baseUrl}${endpoint}?${status}`;
//       }
//       window.open(url, "_blank");
//     } catch (error) {
//       toast.error("Something went wrong!", {
//         position: toast.POSITION.BOTTOM_RIGHT,
//       });
//     }
//   };

//   const downloadPdf = () => {
//     try {
//       let baseUrl = process.env.REACT_APP_API_URL;
//       let endpoint = `pdf-sdrfreport-per-district/${admin.id}`;
//       let district_id = currentDistrict ? currentDistrict : null;
//       let status = !!currentStatus ? `status[]=${currentStatus}` : null;
//       let start_date = currentDate?.start_date ? currentDate?.start_date : null;
//       let end_date = currentDate?.end_date ? currentDate?.end_date : null;
//       let url;
//       if (Boolean(currentDistrict)) {
//         url = `${baseUrl}${endpoint}?district_id=${district_id}&${status}`;
//       }
//       else if (currentDate) {
//         url = `${baseUrl}${endpoint}?start_date=${start_date}&end_date=${end_date}`;
//       } else {
//         url = `${baseUrl}${endpoint}?${status}`;
//       }
//       window.open(url, "_blank");
//     } catch (error) {
//       toast.error("Something went wrong!", {
//         position: toast.POSITION.BOTTOM_RIGHT,
//       });
//     }
//   };

  const columns = [
    {
      name: "#",
      selector: (props) => filtered.indexOf(props) + 1,
      sortable: true,
      width: "50px",
    },
    {
      name: "Application ID",
      selector: (row) => row.application_id,
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: "Applicant Name",
      selector: (row) => row.applicant_name,
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: "Nature Of Disaster",
      selector: (row) => row?.nature?.map((item) => item?.title).join(", "),
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: "Item Damaged",
      selector: (row) =>
        row?.application_third_meta
          ?.map((item) => item?.first_damage_classification?.title)
          .join(", "),
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: "Category",
      selector: (row) =>
        row?.application_third_meta
          ?.map((item) => item?.second_damage_classification?.damage_name)
          .join(", "),
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: "Date Of Occurrence",
      selector: (row) => row.date_of_occurrence,
      sortable: true,
    },
    {
      name: "District",
      selector: (row) => row.district?.district_name,
      sortable: true,
      maxWidth: "100px",
    },
    {
      name: "Status",
      selector: (row) => <StatusBadge status={row.status} />,
      sortable: true,
      maxWidth: "150px",
    },
  ];

  const getCounters = async (dates) => {
    const body = { ...dates };
    // console.log('currentDate', currentDate?.start_date, currentDate?.end_date);
    const { data } = await Api("sdrf_report_per_district", "POST", body);
    // console.log('ddd',data.status);

    if (data.status === 1) {
        // console.log('date-filter',data.data);
        setApplication(data.data);
        setApplicationCount(data.count);
        // console.log('getCountersCount',applicationCount);
        setIsApplicationLoaded(true);
        setIsLoading(false);
    }
  };

  const onSubmit = (data) => {
    let body = {
      start_date: "",
      end_date: "",
    };
    if (data.count_date_range === "Current Financial Year") {
      if (dayjs().format("M") > 2) {
        body.start_date = dayjs("1 3", "D M").format("YYYY-MM-DD");
        body.end_date = dayjs("2", "M")
          .add(1, "year")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else {
        body.start_date = dayjs("1 3", "D M")
          .subtract(1, "year")
          .format("YYYY-MM-DD");
        body.end_date = dayjs("2", "M").endOf("month").format("YYYY-MM-DD");
      }
    } else if (data.count_date_range === "Daily") {
      body.start_date = dayjs().format("YYYY-MM-DD");
      body.end_date = dayjs().format("YYYY-MM-DD");
    } else if (data.count_date_range === "Weekly") {
      body.start_date = dayjs().format("YYYY-MM-DD");
      body.end_date = dayjs().format("YYYY-MM-DD");
    } else if (data.count_date_range === "Current Month") {
      body.start_date = dayjs().startOf("month").format("YYYY-MM-DD");
      body.end_date = dayjs().endOf("month").format("YYYY-MM-DD");
    } else if (data.count_date_range === "Previous Month") {
      body.start_date = dayjs()
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
      body.end_date = dayjs()
        .subtract(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD");
    } else if (data.count_date_range === "Custom Dates") {
      body.start_date = dayjs(data?.count_start_date, "DD MMM YYYY").format(
        "YYYY-MM-DD"
      );
      body.end_date = dayjs(data?.count_end_date, "DD MMM YYYY").format(
        "YYYY-MM-DD"
      );
    }
    // else {
    //   if (dayjs().format("M") > 2) {
    //     body.start_date = dayjs("1 3", "D M").format("YYYY-MM-DD");
    //     body.end_date = dayjs("2", "M")
    //       .add(1, "year")
    //       .endOf("month")
    //       .format("YYYY-MM-DD");
    //   } else {
    //     body.start_date = dayjs("1 3", "D M")
    //       .subtract(1, "year")
    //       .format("YYYY-MM-DD");
    //     body.end_date = dayjs("2", "M").endOf("month").format("YYYY-MM-DD");
    //   }
    // }
    getCounters(body);
    setCurrentDate(body);
  };

  useEffect(() => {
    if (!!applicationCount) {
      setPdfList();
    }
  }, [applicationCount]);

  const setPdfList = () => {
    let rangeSize = 200;
    let maxValue = applicationCount;
    // let maxValue = 1500;
    const result = [];
    for (let i = 1; i <= maxValue; i += rangeSize) {
      const start = i;
      const end = Math.min(i + rangeSize - 1, maxValue);
      const rangeString = `${start}-${end}`;
      result.push(rangeString);
    }
    // console.log('resultdd', result);
    // console.log('applicatCount', maxValue);

    setPdfNumberList(result);
  };

  return (
    <>
      {/* <div className="bg-white rounded px-3 py-5 shadow"> */}
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="mb-3">
          <div className="row g-2">
            <div className="col-xl-4">


            <div className="mb-2">
             <form onSubmit={handleSubmit(onSubmit)}>
                 <div className="row g-2">
            <div className="col-12 col-lg-auto text-lg-end">
              <label className="m-0 fw-bold py-lg-1 small">Filters:</label>
            </div>
            <div className="col-lg-5 col-md col-sm-4">
              <div className="">
                <select
                  {...register("count_date_range")}
                  className="form-select form-select-sm"
                >
                  <option value="">Please Select</option>
                  <option value="Current Financial Year">
                    Current Financial Year
                  </option>
                  <option value="Daily">Daily</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Current Month">Current Month</option>
                  <option value="Previous Month">Previous Month</option>
                  <option value="Custom Dates">Custom Dates</option>
                </select>
              </div>
            </div>
            {watch("count_date_range") === "Custom Dates" && (
              <>
                <div className="col-lg-2 col-md col-sm-4 col-6">
                  <Controller
                    control={control}
                    name="count_start_date"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        inputClass="form-control form-control-sm"
                        maxDate={new Date()}
                        placeholder="Start Date"
                        format="DD MMM YYYY"
                        editable={true}
                        value={field.value || ""}
                        onChange={(date) => {
                          field.onChange(date?.format?.("DD MMM YYYY"));
                        }}
                      />
                    )}
                  />
                  {errors.count_start_date && (
                    <div className="error_line">Start date is required</div>
                  )}
                </div>
                <div className="col-lg-2 col-md col-sm-4 col-6">
                  <Controller
                    control={control}
                    name="count_end_date"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        inputClass="form-control form-control-sm"
                        maxDate={new Date()}
                        minDate={
                          dayjs(watch("count_start_date"), "DD MMM YYYY").$d
                        }
                        placeholder="End Date"
                        format="DD MMM YYYY"
                        editable={true}
                        value={field.value || ""}
                        onChange={(date) => {
                          field.onChange(date?.format?.("DD MMM YYYY"));
                        }}
                      />
                    )}
                  />
                  {errors.count_end_date && (
                    <div className="error_line">End date is required</div>
                  )}
                </div>
              </>
            )}
            <div className="col-auto">
              <div className="">
                <input
                  type="submit"
                  value="Show"
                  className="btn btn-primary btn-sm px-4"
                />
              </div>
            </div>
          </div>
        </form>
            </div>


              <h5 className="text-dark">Generate Report By District</h5>
            </div>

            <div className="col-xl-8">
              <div className="">
                <div className="row g-2">
                  <div className="col-sm-6 col-lg-3">
                    <div className="">
                      <select
                        className="form-select"
                        name="district_name"
                        onChange={(e) => filterDistrict(e)}
                        disabled={isLoading}
                      >
                        <option value="">All Districts</option>
                        {districtList
                          ?.filter((districts) => {
                            return districts.status === "Active";
                          })
                          .map((districts) => {
                            return (
                              <option value={districts?.id} key={districts?.id}>
                                {districts?.district_name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3">
                    <div className="">
                      <select
                        className="form-select"
                        name="status"
                        onChange={(e) => filterStatus(e)}
                        disabled={isLoading}
                      >
                        <option value="">All Status</option>
                        {Status?.map((item) => {
                          return (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3">
                    <div className="">
                      <input
                        type="text"
                        placeholder="Search Application"
                        className="form-control"
                        value={filter}
                        onChange={searchInput}
                        disabled={isLoading}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3">
                    <div className="d-flex gap-2">
                      <TooltipWidget title="Download Excel">
                        <button
                        //   onClick={downloadExcel}
                          onClick={excelDownloadModalShow}
                          className="btn btn-success flex-grow-1"
                          type="button"
                        >
                          <FaRegFileExcel />
                        </button>
                      </TooltipWidget>
                      <TooltipWidget title="Download PDF">
                        <button
                        //   onClick={downloadPdf}
                          onClick={pdfDownloadModalShow}
                          className="btn btn-danger  flex-grow-1"
                          type="button"
                        >
                          <FaRegFilePdf />
                        </button>
                      </TooltipWidget>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          {isApplicationLoaded ? (
            <Table columns={columns} data={filtered} />
          ) : (
            <Loader />
          )}
        </div>
      </div>

      <ModalBox
        show={isExcelDownloadModalShow}
        hide={excelDownloadModalclose}
        title="Choose range for download Excel"
      >
        <div className="">
          <form onSubmit={handleSubmit1(onDownloadExcel)}>
            <div className="row g-3 justify-content-center">
              <div className="col-md-12">
                <div className="">
                  <select className="form-select" {...register1('range', {
                    required:true
                  })}>
                    <option value="">Select Range</option>
                    {pdfNumberList?.map((item, i) => (
                      <option key={i} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                  {errors1.range && (
                  <div className="error_line">Range is required</div>
                )}
                </div>
              </div>
              <div className="col-md-4">
                <div>
                  <input className="btn btn-primary w-100" type="submit" value="Submit" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </ModalBox>
      <ModalBox
        show={isPdfDownloadModalShow}
        hide={pdfDownloadModalclose}
        title="Choose range for download PDF"
      >
        <div className="">
          <form onSubmit={handleSubmit1(onDownloadPdf)}>
            <div className="row g-3 justify-content-center">
              <div className="col-md-12">
                <div className="">
                  <select className="form-select" {...register1('range', {
                    required:true
                  })}>
                    <option value="">Select Range</option>
                    {pdfNumberList?.map((item, i) => (
                      <option key={i} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                  {errors1.range && (
                  <div className="error_line">Range is required</div>
                )}
                </div>
              </div>
              <div className="col-md-4">
                <div>
                  <input className="btn btn-primary w-100" type="submit" value="Submit" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </ModalBox>
    </>
  );
};

export default GenerateReport;
